import React, { PureComponent, Fragment } from "react";
import "./index.css";
import NoImg from "../../../img/no-img.jpg";
// components
import TrendingToday from "./TrendingToday";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Skeleton from "@material-ui/lab/Skeleton";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";

import {
  getAllPosts,
  getPostByCategory,
} from "../../../store/actions/posts-actions";
import { addCount } from "../../../store/actions/app-state-action";
import {
  API_STORAGE_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../../constants/api";
class LatestNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: [],
      latest: [],
      foreign: [],
      sports: [],
      politics: [],
      business: [],
      others: [],
      success: false,
      category: {},
      successForeign: false,
      successSports: false,
      successPolitics: false,
      successBusiness: false,
      successOther: true,
      name: "சமீபத்திய",
      alias: "දැන් පුවත්",
      id: 8,
      activeList: {
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      active: 0,
      adList: [],
    };
  }

  componentDidMount() {
    if (this.props.postState.success) {
      this.setState({
        ...this.state,
        show: this.props.postState.data,
        latest: this.props.postState.data,
        success: true,
      });
    }
    if (
      this.props.postCategoryState["அரசியல்"] &&
      this.props.postCategoryState["அரசியல்"].success &&
      this.props.postCategoryState["விளையாட்டு"] &&
      this.props.postCategoryState["விளையாட்டு"].success &&
      this.props.postCategoryState["வெளிநாட்டு"] &&
      this.props.postCategoryState["வெளிநாட்டு"].success &&
      this.props.postCategoryState["வணிகம்"] &&
      this.props.postCategoryState["வணிகம்"].success &&
      this.props.postCategoryState[5] &&
      this.props.postCategoryState[5].success
    ) {
      this.setState({
        ...this.state,
        latest: this.props.postState.data,
        foreign: this.props.postCategoryState["வெளிநாட்டு"].data,
        successForeign: true,
        sports: this.props.postCategoryState["விளையாட்டு"].data,
        successSports: true,
        politics: this.props.postCategoryState["அரசியல்"].data,
        successPolitics: true,
        business: this.props.postCategoryState["வணிகம்"].data,
        successBusiness: true,
        others: this.props.postCategoryState[5].data,
        successOther: true,
      });
    } else {
      this.props.getPostByCategory("சமீபத்திய", 1, 10); //current
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postState !== prevProps.postState) {
      if (this.props.postState.success) {
        this.setState({
          latest: this.props.postState.data,
          show: this.props.postState.data,
          success: true,
        });
      }
    }
    if (this.props.postCategoryState !== prevProps.postCategoryState) {
      if (
        this.props.postCategoryState["வெளிநாட்டு"] &&
        this.props.postCategoryState["வெளிநாட்டு"].success
      ) {
        this.setState({
          foreign: this.props.postCategoryState["வெளிநாட்டு"].data,
          successForeign: true,
        });
      }

      if (
        this.props.postCategoryState["விளையாட்டு"] &&
        this.props.postCategoryState["விளையாட்டு"].success
      ) {
        this.setState({
          sports: this.props.postCategoryState["விளையாட்டு"].data,
          successSports: true,
        });
      }

      if (
        this.props.postCategoryState["அரசியல்"] &&
        this.props.postCategoryState["அரசியல்"].success
      ) {
        this.setState({
          politics: this.props.postCategoryState["அரசியல்"].data,
          successPolitics: true,
        });
      }

      if (
        this.props.postCategoryState["வணிகம்"] &&
        this.props.postCategoryState["வணிகம்"].success
      ) {
        this.setState({
          business: this.props.postCategoryState["வணிகம்"].data,
          successBusiness: true,
        });
      }

      if (
        this.props.postCategoryState["Other"] &&
        this.props.postCategoryState["Other"].success
      ) {
        this.setState({
          others: this.props.postCategoryState["Other"].data,
          successOther: true,
        });
      }
    }

    if (prevProps.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
  }

  handleRedirect = (id) => {
    // this.props.history.push(`/article/` + id);
    window.top.location.href = `/article.php?slug=` + id;
  };

  handleCategorySwitch = (key, status, name, alias, id, prev, next) => {
    this.setState({
      ...this.state,
      show: key === "latest" ? this.state.latest : this.state[key],
      success: status,
      name: name,
      id: id,
      alias: alias,
      activeList: { ...this.state.activeList, [prev]: false, [next]: true },
      active: next,
    });

    console.log("show", this.state.show);
  };

  handleCategory = () => {
    // this.props.history.push(
    //   `/category/` + this.state.id + `/` + this.state.alias
    // );
    window.top.location.href =
      `/category/` + this.state.id + "/" + this.state.alias;
  };

  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.top.location.href = url;
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  addImgErrorDefaultSrc = (e, item) => {
    e.target.src =
      item.length !== 0 && item ? API_STORAGE_BASE_URL + item : NoImg;
  };

  render() {
    const items = [1, 2, 3, 4, 5, 6];
    return (
      <Fragment>
        <div className="latest-news-wrapper">
          <div className="row mx-0">
            <div className="col-12 col-lg-8 pr-0 pr-lg-4">
              <div className="row">
                <div className="col-12 col-md-3 pl-0">
                  <p className="ln-title-01">{this.state.name}</p>
                </div>
                <div className="col-12 col-md-9 d-flex row justify-content-center justify-content-sm-end mx-0">
                  <p
                    className={
                      this.state.activeList[0]
                        ? "vg-text-01 text-active"
                        : "vg-text-01"
                    }
                    onClick={(e) =>
                      this.handleCategorySwitch(
                        "latest",
                        this.state.success,
                        "சமீபத்திய",
                        "சமீபத்திய",
                        8,
                        this.state.active,
                        0
                      )
                    }
                  >
                    சமீபத்திய
                  </p>
                  {/* <p
                    className={
                      this.state.activeList[5]
                        ? "vg-text-01 text-active"
                        : "vg-text-01"
                    }
                    onClick={(e) =>
                      this.handleCategorySwitch(
                        "உள்ளூர்",
                        this.state.successOther,
                        "Local News",
                        "මෙන්න ඇත්ත",
                        5,
                        this.state.active,
                        5
                      )
                    }
                  >
                    உள்ளூர்
                  </p> */}
                  <p
                    className={
                      this.state.activeList[1]
                        ? "vg-text-01 text-active"
                        : "vg-text-01"
                    }
                    onClick={(e) =>
                      this.handleCategorySwitch(
                        "politics",
                        this.state.successPolitics,
                        "அரசியல்",
                        "அரசியல்",
                        12,
                        this.state.active,
                        1
                      )
                    }
                  >
                    அரசியல்
                  </p>
                  <p
                    className={
                      this.state.activeList[2]
                        ? "vg-text-01 text-active"
                        : "vg-text-01"
                    }
                    onClick={(e) =>
                      this.handleCategorySwitch(
                        "foreign",
                        this.state.successForeign,
                        "வெளிநாட்டு",
                        "வெளிநாட்டு",
                        10,
                        this.state.active,
                        2
                      )
                    }
                  >
                    வெளிநாட்டு
                  </p>
                  <p
                    className={
                      this.state.activeList[3]
                        ? "vg-text-01 text-active"
                        : "vg-text-01"
                    }
                    onClick={(e) =>
                      this.handleCategorySwitch(
                        "sports",
                        this.state.successSports,
                        "விளையாட்டு",
                        "விளையாட்டு",
                        11,
                        this.state.active,
                        3
                      )
                    }
                  >
                    விளையாட்டு
                  </p>
                  <p
                    className={
                      this.state.activeList[4]
                        ? "vg-text-01 text-active"
                        : "vg-text-01"
                    }
                    onClick={(e) =>
                      this.handleCategorySwitch(
                        "business",
                        this.state.successBusiness,
                        "வணிகம்",
                        "வணிகம்",
                        2,
                        this.state.active,
                        4
                      )
                    }
                  >
                    வணிகம்
                  </p>
                  {/* <p
                    className="vg-text-03"
                    onClick={(e) => this.handleCategory()}
                  >
                    View More
                  </p> */}
                </div>
              </div>
              <div className="row">
                {this.state.success ? (
                  <Fragment>
                    {this.state.show.length !== 0 ? (
                      this.state.show
                        .map((item, index) => {
                          var thumbnail = "";
                          if (item.Thumbnail) {
                            var getImagePath = item.Thumbnail.split("/");
                            var thumb = getImagePath[3].split(".");
                            thumbnail = thumb[0];
                          }
                          return (
                            <Fragment key={index}>
                              <div className="col-12 col-sm-4 pl-0 mt-3">
                                <div
                                  className="card latest-news-card h-100"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    this.handleRedirect(item.Slug)
                                  }
                                >
                                  <div className="latest-news-img-container placeholder-img">
                                    <img
                                      className="latest-news-img"
                                      src={
                                        thumbnail.length !== 0 && thumbnail
                                          ? API_STORAGE_BASE_THUMB_URL +
                                            thumbnail +
                                            ".jpeg"
                                          : item.Thumbnail.length !== 0 &&
                                            item.Thumbnail
                                          ? API_STORAGE_BASE_URL +
                                            item.Thumbnail
                                          : NoImg
                                      }
                                      onError={(e) => {
                                        this.addImgErrorDefaultSrc(
                                          e,
                                          item.Thumbnail
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="card-body p-3">
                                    <button
                                      type="button"
                                      className="btn btn-sm latest-news-button"
                                    >
                                      {item.Category}
                                    </button>
                                    <div className="row pl-3 pr-3 latest-news-subtext">
                                      <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                      <p className="ln-text-date">
                                        {moment(item.DateCreated)
                                          .local()
                                          .startOf("seconds")
                                          .fromNow()}
                                      </p>
                                    </div>
                                    <p className="ln-text-01">
                                      {item.Title.length > 60
                                        ? item.Title.slice(0, 60) + "..."
                                        : item.Title}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })
                        .slice(0, 6)
                    ) : (
                      <div className="row d-flex mx-0 justify-content-center w-100">
                        <p>There are no articles.</p>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {items.map((item, index) => {
                      return (
                        <div className="col-12 col-sm-4 pl-0 mt-3" key={index}>
                          <div className="card latest-news-card">
                            <div className="latest-news-img-container">
                              <Skeleton variant="rect" height={189} />
                            </div>
                            <div className="card-body p-3">
                              <div className="row pl-3 pr-3 mb-2">
                                <Skeleton
                                  variant="rect"
                                  width={110}
                                  height={18}
                                />
                              </div>
                              <Skeleton variant="rect" height={40} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 pl-0 pl-sm-2 pr-0">
              <div className="row mt-3">
                <div className="col-12">
                  <div
                    className="card social-media-card"
                    style={{
                      fontFamily: "'Inter', sans-serif",
                    }}
                  >
                    <div className="card-body">
                      <p className="ln-title-01 mb-4">Get in Touch</p>
                      <div className="row mt-4">
                        <div className="col-6">
                          <a
                            className="my-auto"
                            href="https://twitter.com/citizen_newslk"
                            target="_top"
                          >
                            <button
                              type="button"
                              className="btn btn-block social-media-btn twitter-btn"
                            >
                              <i
                                className="fab fa-twitter  mr-2 fa-lg"
                                aria-hidden="true"
                              ></i>
                              Twitter
                            </button>
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="https://www.facebook.com/citizenlk/"
                            target="_top"
                          >
                            <button
                              type="button"
                              className="btn btn-block social-media-btn facebook-btn"
                            >
                              <i
                                className="fab fa-facebook-square mr-2 fa-lg"
                                aria-hidden="true"
                              ></i>
                              Facebook
                            </button>
                          </a>
                        </div>
                        <div className="col-6 mt-4">
                          <a
                            href="https://www.instagram.com/citizen.lk/"
                            target="_top"
                          >
                            <button
                              type="button"
                              className="btn btn-block social-media-btn instagram-btn"
                            >
                              <i
                                className="fab fa-instagram mr-2 fa-lg"
                                aria-hidden="true"
                              ></i>
                              Instagram
                            </button>
                          </a>
                        </div>
                        <div className="col-6 mt-4">
                          <a
                            className="my-auto"
                            href="https://www.youtube.com/channel/UCNudBJTq-9sEGCgtL2F0AZA"
                            target="_top"
                          >
                            <button
                              type="button"
                              className="btn btn-block social-media-btn youtube-btn"
                            >
                              <i
                                className="fab fa-youtube mr-2 fa-lg"
                                aria-hidden="true"
                              ></i>
                              YouTube
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 d-flex align-items-end">
                  {this.state.adList &&
                    this.state.adList.length !== 0 &&
                    this.state.adList.map((item, index) => {
                      if (item.Section === "Section 03") {
                        return (
                          <div className="ad2 mt-2 mb-2" key={index}>
                            <img
                              src={API_STORAGE_BASE_URL + item.Thumbnail}
                              alt="advertisement"
                              onClick={(e) =>
                                this.handleCount(item.Id, item.Count, item.Url)
                              }
                              onError={this.addDefaultSrc}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        );
                      }
                    })}
                </div>
                <div className="col-12">
                  <TrendingToday />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.postStore.posts,
    postCategoryState: state.postStore.category,
    adState: state.appStore.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllPosts: (from, to) => dispatch(getAllPosts(from, to)),
  getPostByCategory: (key, from, to) =>
    dispatch(getPostByCategory(key, from, to)),
  addCount: (data) => dispatch(addCount(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LatestNews)
);
