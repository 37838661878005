import React, { Fragment, PureComponent } from "react";
import "./index.css";
import verified from "../../../img/verified.svg";
import LiveNews from "./LiveNews";
import {
  getAllPosts,
  getAllFactCheck,
} from "../../../store/actions/posts-actions";
import { getAllAds, addCount } from "../../../store/actions/app-state-action";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
// import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import NoImg from "../../../img/no-img.jpg";
import moment from "moment";
import {
  API_STORAGE_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../../constants/api";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Remark } from "react-remark";
import MDEditor from "@uiw/react-md-editor";
class NewsFeed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAllFactCheck: true,
      isFactCheck: false,
      isProgress: false,
      isFalse: false,
      data: [],
      factCheck: {},
      adList: [],
      loading: true,
      success: false,
      isLoading: true,
      isFactSearch: false,
      factSearchText: "",
    };
  }

  componentDidMount() {
    // this.props.getAllPosts(0);
    this.props.getAllFactCheck(0, 50, "Tamil");
    this.props.getAllAds();
  }

  componentDidUpdate(prevState) {
    if (prevState.postState !== this.props.postState) {
      if (
        this.props.postState.posts.success &&
        !this.props.postState.posts.isLoading
      ) {
        this.setState({
          ...this.state,
          data: this.props.postState.posts.data.filter(
            (item) => item.IsFeatured === "1"
          ),
          loading: false,
          success: true,
        });
      }

      if (
        this.props.factCheckState.success &&
        !this.props.factCheckState.isLoading
      ) {
        this.setState({
          ...this.state,
          data: this.props.postState.posts.data.filter(
            (item) => item.IsFeatured === "1"
          ),
          factCheck: this.props.factCheckState.data,
          isLoading: false,
          success: true,
        });
      }
    }

    if (prevState.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
  }

  handleAllFactCheck = () => {
    this.setState({
      ...this.state,
      isAllFactCheck: true,
      isFactCheck: false,
      isProgress: false,
      isFalse: false,
      isFactSearch: false,
      factSearchText: "",
      factCheck: this.props.factCheckState.data
        ? this.props.factCheckState.data
        : [],
    });
  };

  handleFactCheck = () => {
    this.setState({
      ...this.state,
      isFactCheck: true,
      isProgress: false,
      isFalse: false,
      isAllFactCheck: false,
      isFactSearch: false,
      factSearchText: "",
      factCheck: this.props.factCheckState.data
        ? this.props.factCheckState.data
        : [],
    });
  };

  handleProgress = () => {
    this.setState({
      ...this.state,
      isProgress: true,
      isFactCheck: false,
      isFalse: false,
      isAllFactCheck: false,
      isFactSearch: false,
      factSearchText: "",
      factCheck: this.props.factCheckState.data
        ? this.props.factCheckState.data
        : [],
    });
  };

  handleFalse = () => {
    this.setState({
      ...this.state,
      isFalse: true,
      isProgress: false,
      isFactCheck: false,
      isAllFactCheck: false,
      isFactSearch: false,
      factSearchText: "",
      factCheck: this.props.factCheckState.data
        ? this.props.factCheckState.data
        : [],
    });
  };

  handleRedirect = (id) => {
    // this.props.history.push(`/article/` + id);
    window.top.location.href = `/article.php?slug=` + id;
  };

  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.location.assign(url);
  };
  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  handleSearch = () => {
    var facts = [];
    if (this.state.isFactSearch) {
      this.setState({
        ...this.state,
        isFactSearch: false,
        factSearchText: "",
        factCheck: this.props.factCheckState.data
          ? this.props.factCheckState.data
          : [],
      });
    } else {
      if (this.state.factSearchText) {
        facts = this.props.factCheckState.data
          ? this.props.factCheckState.data.filter((fact, index) =>
              fact.Title.toLowerCase().includes(
                this.state.factSearchText.toLowerCase()
              )
            )
          : [];

        this.setState({ ...this.state, isFactSearch: true, factCheck: facts });
      }
    }
  };

  addImgErrorDefaultSrc = (e, item) => {
    e.target.src =
      item.length !== 0 && item ? API_STORAGE_BASE_URL + item : NoImg;
  };

  render() {
    const fcUrl = "https://fc.citizen.lk/article/";
    return (
      <Fragment>
        <div className="news-feed-wrapper">
          <div className="row">
            {/* <div className="col-12 order-2 order-sm-1 pl-3 pl-sm-0">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 01") {
                    return (
                      <div className="ad mt-4 mb-4" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail}
                          alt="advertisement"
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          onError={this.addDefaultSrc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  }
                })}
            </div> */}
            <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12   pl-3 pl-sm-0 mt-3 fact-check-lg">
              <div className="row d-flex mx-0 mb-1">
                <div className="col-12 col-md-6 col-sm-12 p-0">
                  <h5>Fact Check</h5>
                </div>
                <div className="col-12 col-md-6 col-sm-12 d-flex flex-row mx-0 p-0">
                  <input
                    className="form-control w-100 fact-search-input"
                    type="text"
                    placeholder="Search here"
                    value={this.state.factSearchText}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        factSearchText: e.target.value,
                      })
                    }
                    disabled={this.state.isFactSearch}
                  />
                  <button
                    type="button"
                    className="fact-search-btn ml-1"
                    onClick={() => this.handleSearch()}
                  >
                    {this.state.isFactSearch ? (
                      <i className="fa fa-times" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-search" aria-hidden="true"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="news-feed-button">
                <div
                  className="btn-group special"
                  role="group"
                  aria-label="..."
                  style={{ overflowX: "auto" }}
                >
                  <button
                    type="button"
                    className="btn btn-success btn-lg mr-1 d-flex justify-content-center"
                    style={{ backgroundColor: "rgb(76 78 76)" }}
                    onClick={(e) => this.handleAllFactCheck()}
                  >
                    All
                  </button>
                  <button
                    type="button"
                    className="btn btn-success btn-lg mr-1 d-flex justify-content-center"
                    style={{ backgroundColor: "#3B7937" }}
                    onClick={(e) => this.handleFactCheck()}
                  >
                    Verified
                  </button>
                  <button
                    type="button"
                    className="btn btn-success btn-lg mr-1"
                    style={{ backgroundColor: "#EB963F" }}
                    onClick={(e) => this.handleProgress()}
                  >
                    In Progress
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-lg"
                    style={{ backgroundColor: "#BC332F" }}
                    onClick={(e) => this.handleFalse()}
                  >
                    False/Misleading
                  </button>
                </div>
                {this.state.isAllFactCheck ? (
                  <div
                    className="card fact-check-card"
                    style={{ border: "2px solid rgb(76 78 76)" }}
                  >
                    <div className="card-body">
                      {this.state.isLoading ? (
                        <div className="row">
                          <div className="col-2 col-md-2">
                            <Skeleton variant="circle" height={30} width={30} />
                          </div>
                          <div className="col-10 col-md-10 pl-0 pl-md-3">
                            <p className="fact-check-text-01">
                              <Skeleton variant="rect" height={20} />
                            </p>
                            <p className="fact-check-subtext">
                              <Skeleton variant="rect" height={14} width={75} />
                            </p>
                            <p className="fact-check-text-02">
                              <Skeleton variant="rect" height={50} />
                            </p>
                          </div>
                        </div>
                      ) : this.state.factCheck.length > 0 ? (
                        this.state.factCheck.map((item, index) => {
                          return (
                            <div className="row mb-2" key={index}>
                              <div className="col-2 col-sm-1 col-md-1 col-lg-2">
                                {item.Status === "1" ? (
                                  <i
                                    class="fas fa-badge-check"
                                    style={{
                                      fontSize: "20px",
                                      color: "#3B7937",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                ) : item.Status === "2" ? (
                                  <i
                                    className="fa fa-refresh text-warning"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "18px",
                                      color: "#EB963F",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "20px",
                                      color: "#BC332F",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                )}
                              </div>
                              <div className="col-10 col-md-10 pl-0">
                                <a
                                  href={fcUrl + item.Slug}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p className="fact-check-text-01">
                                    Fact Check: {item.Title}
                                  </p>
                                </a>
                                <p className="fact-check-subtext">
                                  {item.Source} &nbsp;
                                  {moment(item.DateCreated).format(
                                    "DD MMMM YYYY, hh:mm:ss A"
                                  )}
                                </p>
                                {/* <p
                                  className="fact-check-text-02"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.Description.length > 368
                                        ? `${item.Description.substring(
                                            0,
                                            368
                                          )}...`
                                        : item.Description,
                                  }}
                                ></p> */}

                                {/* <Remark>
                                  {item.Description.length > 368
                                    ? `${item.Description.substring(0, 368)}...`
                                    : item.Description}
                                </Remark> */}
                                <MDEditor.Markdown
                                  source={
                                    item.Description.length > 368
                                      ? `${item.Description.substring(
                                          0,
                                          368
                                        )}...`
                                      : item.Description
                                  }
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="row mx-0 d-flex justify-content-center">
                          There are no facts.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {this.state.isFactCheck ? (
                  <div className="card fact-check-card">
                    <div className="card-body">
                      {this.state.isLoading ? (
                        <div className="row">
                          <div className="col-2 col-md-2">
                            <Skeleton variant="circle" height={30} width={30} />
                          </div>
                          <div className="col-10 col-md-10 pl-0">
                            <p className="fact-check-text-01">
                              <Skeleton variant="rect" height={20} />
                            </p>
                            <p className="fact-check-subtext">
                              <Skeleton variant="rect" height={14} width={75} />
                            </p>
                            <p className="fact-check-text-02">
                              <Skeleton variant="rect" height={50} />
                            </p>
                          </div>
                        </div>
                      ) : this.state.factCheck.filter((f) => f.Status === "1")
                          .length > 0 ? (
                        this.state.factCheck.map((item, index) => {
                          if (item.Status === "1") {
                            return (
                              <div className="row mb-2" key={index}>
                                <div className="col-2 col-sm-1 col-md-1 col-lg-2">
                                  <i
                                    class="fas fa-badge-check"
                                    style={{
                                      fontSize: "20px",
                                      color: "#3B7937",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                </div>
                                <div className="col-10 col-md-10 pl-0">
                                  <a
                                    href={fcUrl + item.Slug}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="fact-check-text-01">
                                      Fact Check: {item.Title}
                                    </p>
                                  </a>
                                  <p className="fact-check-subtext">
                                    {item.Source} &nbsp;
                                    {moment(item.DateCreated).format(
                                      "DD MMMM YYYY, hh:mm:ss A"
                                    )}
                                  </p>
                                  {/* <p
                                    className="fact-check-text-02"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.Description.length > 368
                                          ? `${item.Description.substring(
                                              0,
                                              368
                                            )}...`
                                          : item.Description,
                                    }}
                                  ></p> */}
                                  {/* <Remark>
                                    {item.Description.length > 368
                                      ? `${item.Description.substring(
                                          0,
                                          368
                                        )}...`
                                      : item.Description}
                                  </Remark> */}
                                  <MDEditor.Markdown
                                    source={
                                      item.Description.length > 368
                                        ? `${item.Description.substring(
                                            0,
                                            368
                                          )}...`
                                        : item.Description
                                    }
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })
                      ) : (
                        <div className="row mx-0 d-flex justify-content-center">
                          There are no facts.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {this.state.isProgress ? (
                  <div className="card progress-card">
                    <div className="card-body">
                      {this.state.isLoading ? (
                        <div className="row">
                          <div className="col-2 col-md-2">
                            <Skeleton variant="circle" height={30} width={30} />
                          </div>
                          <div className="col-10 col-md-10 pl-0">
                            <p className="fact-check-text-01">
                              <Skeleton variant="rect" height={20} />
                            </p>
                            <p className="fact-check-subtext">
                              <Skeleton variant="rect" height={14} width={75} />
                            </p>
                            <p className="fact-check-text-02">
                              <Skeleton variant="rect" height={50} />
                            </p>
                          </div>
                        </div>
                      ) : this.state.factCheck.filter((f) => f.Status === "2")
                          .length > 0 ? (
                        this.state.factCheck.map((item, index) => {
                          if (item.Status === "2") {
                            return (
                              <div className="row mb-2" key={index}>
                                <div className="col-2 col-sm-1 col-md-1 col-lg-2">
                                  <i
                                    className="fa fa-refresh text-warning"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "18px",
                                      color: "#EB963F",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                </div>
                                <div className="col-10 col-md-10 pl-0">
                                  <a
                                    href={fcUrl + item.Slug}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="fact-check-text-01">
                                      Fact Check: {item.Title}
                                    </p>
                                  </a>
                                  <p className="fact-check-subtext">
                                    {item.Source} &nbsp;
                                    {moment(item.DateCreated).format(
                                      "DD MMMM YYYY, hh:mm:ss A"
                                    )}
                                  </p>
                                  {/* <p
                                    className="fact-check-text-02"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.Description.length > 368
                                          ? `${item.Description.substring(
                                              0,
                                              368
                                            )}...`
                                          : item.Description,
                                    }}
                                  ></p> */}
                                  {/* <Remark>
                                    {item.Description.length > 368
                                      ? `${item.Description.substring(
                                          0,
                                          368
                                        )}...`
                                      : item.Description}
                                  </Remark> */}
                                  <MDEditor.Markdown
                                    source={
                                      item.Description.length > 368
                                        ? `${item.Description.substring(
                                            0,
                                            368
                                          )}...`
                                        : item.Description
                                    }
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="row mx-0 d-flex justify-content-center">
                          There are no facts.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {this.state.isFalse ? (
                  <div className="card false-card">
                    <div className="card-body">
                      {this.state.isLoading ? (
                        <div className="row">
                          <div className="col-2 col-md-2">
                            <Skeleton variant="circle" height={30} width={30} />
                          </div>
                          <div className="col-10 col-md-10 pl-0">
                            <p className="fact-check-text-01">
                              <Skeleton variant="rect" height={20} />
                            </p>
                            <p className="fact-check-subtext">
                              <Skeleton variant="rect" height={14} width={75} />
                            </p>
                            <p className="fact-check-text-02">
                              <Skeleton variant="rect" height={50} />
                            </p>
                          </div>
                        </div>
                      ) : this.state.factCheck.filter((f) => f.Status === "3")
                          .length > 0 ? (
                        this.state.factCheck.map((item, index) => {
                          if (item.Status === "3") {
                            return (
                              <div className="row mb-2" key={index}>
                                <div className="col-2 col-sm-1 col-md-1 col-lg-2">
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "20px",
                                      color: "#BC332F",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                </div>
                                <div className="col-10 col-md-10 pl-0">
                                  <a
                                    href={fcUrl + item.Slug}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="fact-check-text-01">
                                      Fact Check: {item.Title}
                                    </p>
                                  </a>
                                  <p className="fact-check-subtext">
                                    {item.Source} &nbsp;
                                    {moment(item.DateCreated).format(
                                      "DD MMMM YYYY, hh:mm:ss A"
                                    )}
                                  </p>
                                  {/* <p
                                    className="fact-check-text-02"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.Description.length > 368
                                          ? `${item.Description.substring(
                                              0,
                                              368
                                            )}...`
                                          : item.Description,
                                    }}
                                  ></p> */}
                                  {/* <Remark>
                                    {item.Description.length > 368
                                      ? `${item.Description.substring(
                                          0,
                                          368
                                        )}...`
                                      : item.Description}
                                  </Remark> */}
                                  <MDEditor.Markdown
                                    source={
                                      item.Description.length > 368
                                        ? `${item.Description.substring(
                                            0,
                                            368
                                          )}...`
                                        : item.Description
                                    }
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="row mx-0 d-flex justify-content-center">
                          There are no facts.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12  mt-3">
              {this.state.data.length && this.state.success ? (
                <div
                  id="carouselExampleControls"
                  className=" carousel slide"
                  data-ride="carousel"
                >
                  <div class="carousel-inner ">
                    {this.state.data.length !== 0 &&
                      this.state.data
                        .map((item, index) => {
                          var thumbnail = "";
                          if (item.Thumbnail) {
                            var getImagePath = item.Thumbnail.split("/");
                            var thumb = getImagePath[3].split(".");
                            thumbnail = thumb[0];
                          }
                          return (
                            <div
                              class={
                                index === 0
                                  ? "carousel-item card news-img-card active"
                                  : "carousel-item card news-img-card "
                              }
                              onClick={(e) => this.handleRedirect(item.Slug)}
                            >
                              <img
                                src={
                                  thumbnail.length !== 0 && thumbnail
                                    ? API_STORAGE_BASE_THUMB_URL +
                                      thumbnail +
                                      ".jpeg"
                                    : item.Thumbnail.length !== 0 &&
                                      item.Thumbnail
                                    ? API_STORAGE_BASE_URL + item.Thumbnail
                                    : NoImg
                                }
                                onError={(e) => {
                                  this.addImgErrorDefaultSrc(e, item.Thumbnail);
                                }}
                                style={{
                                  cursor: "pointer",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                alt="..."
                              />
                              <div className="row new-img-info mx-0">
                                <div className="col-12 my-auto">
                                  <p className="news-img-text-01">
                                    {moment(item.DateCreated)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </p>
                                </div>
                                <div className="col-12 mb-0">
                                  {this.state.success ? (
                                    <div
                                      className="news-img-text-02"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.Title.length > 60
                                            ? `${item.Title.substring(
                                                0,
                                                60
                                              )}...`
                                            : item.Title,
                                      }}
                                    ></div>
                                  ) : // <Remark>{item.Title}</Remark>
                                  null}
                                </div>
                              </div>
                            </div>
                          );
                        })
                        .slice(0, 3)}
                  </div>
                  <button
                    class="carousel-control-prev post-carousel-prev-btn"
                    type="button"
                    data-target="#carouselExampleControls"
                    data-slide="prev"
                  >
                    <IoIosArrowBack
                      color="black"
                      className=" product-view-main-next-btn-icon"
                      size={25}
                    />

                    {/* <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span> */}
                  </button>
                  <button
                    class="carousel-control-next post-carousel-next-btn "
                    type="button"
                    data-target="#carouselExampleControls"
                    data-slide="next"
                  >
                    <IoIosArrowForward
                      color="black"
                      className=" product-view-main-next-btn-icon"
                      size={25}
                    />

                    {/* <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span> */}
                  </button>
                </div>
              ) : (
                <Skeleton variant="rect" height={414} />
              )}
              {/* {this.state.data.length && this.state.success ? (
                <div
                  className="card news-img-card"
                  onClick={(e) => this.handleRedirect(this.state.data[0].Slug)}
                >
                  <img
                    onError={this.addDefaultSrc}
                    src={
                      this.state.data[0].Thumbnail.length !== 0 &&
                      this.state.data[0].Thumbnail
                        ? API_STORAGE_BASE_URL + this.state.data[0].Thumbnail
                        : NoImg
                    } // use normal <img> attributes as props
                    style={{
                      cursor: "pointer",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="row new-img-info mx-0">
                    <div className="col-12 my-auto">
                      <p className="news-img-text-01">
                        {moment(this.state.data[0].DateCreated)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                      </p>
                    </div>
                    <div className="col-12 mb-4">
                      {this.state.success ? (
                        <div
                          className="news-img-text-02"
                          dangerouslySetInnerHTML={{
                            __html: this.state.data[0].Title,
                          }}
                        ></div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <Skeleton variant="rect" height={414} />
              )} */}
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-6  col-sm-12 ">
              <LiveNews />
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12   pl-3 pl-sm-0 mt-3 fact-check-sm">
              <div className="row d-flex mx-0 mb-1">
                <div className="col-12 col-md-6 col-sm-12 p-0">
                  <h5>Fact Check</h5>
                </div>
                <div className="col-12 col-md-6 col-sm-12 d-flex flex-row mx-0 p-0">
                  <input
                    className="form-control w-100 fact-search-input"
                    type="text"
                    placeholder="Search here"
                    value={this.state.factSearchText}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        factSearchText: e.target.value,
                      })
                    }
                    disabled={this.state.isFactSearch}
                  />
                  <button
                    type="button"
                    className="fact-search-btn ml-1"
                    onClick={() => this.handleSearch()}
                  >
                    {this.state.isFactSearch ? (
                      <i className="fa fa-times" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-search" aria-hidden="true"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="news-feed-button">
                <div
                  className="btn-group special"
                  role="group"
                  aria-label="..."
                  style={{ overflowX: "auto" }}
                >
                  <button
                    type="button"
                    className="btn btn-success btn-lg mr-1 d-flex justify-content-center"
                    style={{ backgroundColor: "rgb(76 78 76)" }}
                    onClick={(e) => this.handleAllFactCheck()}
                  >
                    All
                  </button>
                  <button
                    type="button"
                    className="btn btn-success btn-lg mr-1 d-flex justify-content-center"
                    style={{ backgroundColor: "#3B7937" }}
                    onClick={(e) => this.handleFactCheck()}
                  >
                    Verified
                  </button>
                  <button
                    type="button"
                    className="btn btn-success btn-lg mr-1"
                    style={{ backgroundColor: "#EB963F" }}
                    onClick={(e) => this.handleProgress()}
                  >
                    In Progress
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-lg"
                    style={{ backgroundColor: "#BC332F" }}
                    onClick={(e) => this.handleFalse()}
                  >
                    False/Misleading
                  </button>
                </div>
                {this.state.isAllFactCheck ? (
                  <div
                    className="card fact-check-card"
                    style={{ border: "2px solid rgb(76 78 76)" }}
                  >
                    <div className="card-body">
                      {this.state.isLoading ? (
                        <div className="row">
                          <div className="col-2 col-md-2">
                            <Skeleton variant="circle" height={30} width={30} />
                          </div>
                          <div className="col-10 col-md-10 pl-0 pl-md-3">
                            <p className="fact-check-text-01">
                              <Skeleton variant="rect" height={20} />
                            </p>
                            <p className="fact-check-subtext">
                              <Skeleton variant="rect" height={14} width={75} />
                            </p>
                            <p className="fact-check-text-02">
                              <Skeleton variant="rect" height={50} />
                            </p>
                          </div>
                        </div>
                      ) : this.state.factCheck.length > 0 ? (
                        this.state.factCheck.map((item, index) => {
                          return (
                            <div className="row mb-2" key={index}>
                              <div className="col-2 col-sm-1 col-md-1 col-lg-2">
                                {item.Status === "1" ? (
                                  <i
                                    class="fas fa-badge-check"
                                    style={{
                                      fontSize: "20px",
                                      color: "#3B7937",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                ) : item.Status === "2" ? (
                                  <i
                                    className="fa fa-refresh text-warning"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "18px",
                                      color: "#EB963F",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "20px",
                                      color: "#BC332F",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                )}
                              </div>
                              <div className="col-10 col-md-10 pl-0">
                                <a
                                  href={fcUrl + item.Slug}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p className="fact-check-text-01">
                                    Fact Check: {item.Title}
                                  </p>
                                </a>
                                <p className="fact-check-subtext">
                                  {item.Source} &nbsp;
                                  {moment(item.DateCreated).format(
                                    "DD MMMM YYYY, hh:mm:ss A"
                                  )}
                                </p>
                                {/* <p
                                  className="fact-check-text-02"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.Description.length > 368
                                        ? `${item.Description.substring(
                                            0,
                                            368
                                          )}...`
                                        : item.Description,
                                  }}
                                ></p> */}
                                {/* <Remark>
                                  {item.Description.length > 368
                                    ? `${item.Description.substring(0, 368)}...`
                                    : item.Description}
                                </Remark> */}
                                <MDEditor.Markdown
                                  source={
                                    item.Description.length > 368
                                      ? `${item.Description.substring(
                                          0,
                                          368
                                        )}...`
                                      : item.Description
                                  }
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="row mx-0 d-flex justify-content-center">
                          There are no facts.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {this.state.isFactCheck ? (
                  <div className="card fact-check-card">
                    <div className="card-body">
                      {this.state.isLoading ? (
                        <div className="row">
                          <div className="col-2 col-md-2">
                            <Skeleton variant="circle" height={30} width={30} />
                          </div>
                          <div className="col-10 col-md-10 pl-0">
                            <p className="fact-check-text-01">
                              <Skeleton variant="rect" height={20} />
                            </p>
                            <p className="fact-check-subtext">
                              <Skeleton variant="rect" height={14} width={75} />
                            </p>
                            <p className="fact-check-text-02">
                              <Skeleton variant="rect" height={50} />
                            </p>
                          </div>
                        </div>
                      ) : this.state.factCheck.filter((f) => f.Status === "1")
                          .length > 0 ? (
                        this.state.factCheck.map((item, index) => {
                          if (item.Status === "1") {
                            return (
                              <div className="row mb-2" key={index}>
                                <div className="col-2 col-sm-1 col-md-1 col-lg-2">
                                  <i
                                    class="fas fa-badge-check"
                                    style={{
                                      fontSize: "20px",
                                      color: "#3B7937",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                </div>
                                <div className="col-10 col-md-10 pl-0">
                                  <a
                                    href={fcUrl + item.Slug}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="fact-check-text-01">
                                      Fact Check: {item.Title}
                                    </p>
                                  </a>
                                  <p className="fact-check-subtext">
                                    {item.Source} &nbsp;
                                    {moment(item.DateCreated).format(
                                      "DD MMMM YYYY, hh:mm:ss A"
                                    )}
                                  </p>
                                  {/* <p
                                    className="fact-check-text-02"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.Description.length > 368
                                          ? `${item.Description.substring(
                                              0,
                                              368
                                            )}...`
                                          : item.Description,
                                    }}
                                  ></p> */}
                                  {/* <Remark>
                                    {item.Description.length > 368
                                      ? `${item.Description.substring(
                                          0,
                                          368
                                        )}...`
                                      : item.Description}
                                  </Remark> */}
                                  <MDEditor.Markdown
                                    source={
                                      item.Description.length > 368
                                        ? `${item.Description.substring(
                                            0,
                                            368
                                          )}...`
                                        : item.Description
                                    }
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })
                      ) : (
                        <div className="row mx-0 d-flex justify-content-center">
                          There are no facts.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {this.state.isProgress ? (
                  <div className="card progress-card">
                    <div className="card-body">
                      {this.state.isLoading ? (
                        <div className="row">
                          <div className="col-2 col-md-2">
                            <Skeleton variant="circle" height={30} width={30} />
                          </div>
                          <div className="col-10 col-md-10 pl-0">
                            <p className="fact-check-text-01">
                              <Skeleton variant="rect" height={20} />
                            </p>
                            <p className="fact-check-subtext">
                              <Skeleton variant="rect" height={14} width={75} />
                            </p>
                            <p className="fact-check-text-02">
                              <Skeleton variant="rect" height={50} />
                            </p>
                          </div>
                        </div>
                      ) : this.state.factCheck.filter((f) => f.Status === "2")
                          .length > 0 ? (
                        this.state.factCheck.map((item, index) => {
                          if (item.Status === "2") {
                            return (
                              <div className="row mb-2" key={index}>
                                <div className="col-2 col-sm-1 col-md-1 col-lg-2">
                                  <i
                                    className="fa fa-refresh text-warning"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "18px",
                                      color: "#EB963F",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                </div>
                                <div className="col-10 col-md-10 pl-0">
                                  <a
                                    href={fcUrl + item.Slug}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="fact-check-text-01">
                                      Fact Check: {item.Title}
                                    </p>
                                  </a>
                                  <p className="fact-check-subtext">
                                    {item.Source} &nbsp;
                                    {moment(item.DateCreated).format(
                                      "DD MMMM YYYY, hh:mm:ss A"
                                    )}
                                  </p>
                                  {/* <p
                                    className="fact-check-text-02"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.Description.length > 368
                                          ? `${item.Description.substring(
                                              0,
                                              368
                                            )}...`
                                          : item.Description,
                                    }}
                                  ></p> */}
                                  {/* <Remark>
                                    {item.Description.length > 368
                                      ? `${item.Description.substring(
                                          0,
                                          368
                                        )}...`
                                      : item.Description}
                                  </Remark> */}
                                  <MDEditor.Markdown
                                    source={
                                      item.Description.length > 368
                                        ? `${item.Description.substring(
                                            0,
                                            368
                                          )}...`
                                        : item.Description
                                    }
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="row mx-0 d-flex justify-content-center">
                          There are no facts.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {this.state.isFalse ? (
                  <div className="card false-card">
                    <div className="card-body">
                      {this.state.isLoading ? (
                        <div className="row">
                          <div className="col-2 col-md-2">
                            <Skeleton variant="circle" height={30} width={30} />
                          </div>
                          <div className="col-10 col-md-10 pl-0">
                            <p className="fact-check-text-01">
                              <Skeleton variant="rect" height={20} />
                            </p>
                            <p className="fact-check-subtext">
                              <Skeleton variant="rect" height={14} width={75} />
                            </p>
                            <p className="fact-check-text-02">
                              <Skeleton variant="rect" height={50} />
                            </p>
                          </div>
                        </div>
                      ) : this.state.factCheck.filter((f) => f.Status === "3")
                          .length > 0 ? (
                        this.state.factCheck.map((item, index) => {
                          if (item.Status === "3") {
                            return (
                              <div className="row mb-2" key={index}>
                                <div className="col-2 col-sm-1 col-md-1 col-lg-2">
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "20px",
                                      color: "#BC332F",
                                      marginTop: "6px",
                                    }}
                                  ></i>
                                </div>
                                <div className="col-10 col-md-10 pl-0">
                                  <a
                                    href={fcUrl + item.Slug}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <p className="fact-check-text-01">
                                      Fact Check: {item.Title}
                                    </p>
                                  </a>
                                  <p className="fact-check-subtext">
                                    {item.Source} &nbsp;
                                    {moment(item.DateCreated).format(
                                      "DD MMMM YYYY, hh:mm:ss A"
                                    )}
                                  </p>
                                  {/* <p
                                    className="fact-check-text-02"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.Description.length > 368
                                          ? `${item.Description.substring(
                                              0,
                                              368
                                            )}...`
                                          : item.Description,
                                    }}
                                  ></p> */}
                                  {/* <Remark>
                                    {item.Description.length > 368
                                      ? `${item.Description.substring(
                                          0,
                                          368
                                        )}...`
                                      : item.Description}
                                  </Remark> */}
                                  <MDEditor.Markdown
                                    source={
                                      item.Description.length > 368
                                        ? `${item.Description.substring(
                                            0,
                                            368
                                          )}...`
                                        : item.Description
                                    }
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="row mx-0 d-flex justify-content-center">
                          There are no facts.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 pl-3 pl-sm-0">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 02") {
                    return (
                      <div className="ad mt-5 mb-5" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail}
                          alt="advertisement"
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          onError={this.addDefaultSrc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.postStore,
    factCheckState: state.postStore.factCheckList,
    adState: state.appStore.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllPosts: (from, to) => dispatch(getAllPosts(from, to)),
  getAllFactCheck: (from, to, language) =>
    dispatch(getAllFactCheck(from, to, language)),
  getAllAds: () => dispatch(getAllAds()),
  addCount: (data) => dispatch(addCount(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsFeed)
);
