import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { resetPassword } from "../../store/actions/app-state-action";

import NewsBackground from "../../img/news-cuate.svg";

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      repassword: "",
      success: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetState !== this.props.resetState) {
      if (this.props.resetState.success) {
        this.setState({ ...this.state, spinner: false }, () => {
          this.showSuccess();
        });
      } else if (this.props.resetState.isError) {
        this.showError("Failed to reset password! Please try again later.");
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  showSuccess = () => {
    this.setState(
      {
        ...this.state,
        validMsg: "Your password has been reset successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          // this.props.history.push("/login");
          window.top.location.href = `/login`;
        }, 2000);
      }
    );
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  };

  passwordStrength = () => {
    var pattern = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );

    if (!pattern.test(this.state.password)) {
      return false;
    }
    return true;
  };

  handleClick = () => {
    if (this.state.password.length === 0) {
      this.showError("Password cannot be empty!");
      return false;
    } else if (this.state.repassword.length === 0) {
      this.showError("Confirm password cannot be empty!");
      return false;
    } else if (this.state.password !== this.state.repassword) {
      this.showError("Password and confirm password should be same!");
    } else if (!this.passwordStrength()) {
      this.showError(
        "Password must container minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character!"
      );
      return false;
    } else {
      this.setState({ ...this.state, spinner: true });
      let email = window.sessionStorage.getItem("email")
        ? window.sessionStorage.getItem("email")
        : false;
      if (email) {
        this.props.resetPassword({
          email: email,
          password: this.state.password,
        });
      }
    }
  };

  render() {
    return (
      <Fragment>
        <div className="login-wrapper">
          <di className="row mx-0">
            <div className="col-md-6 d-flex flex-row align-items-center justify-content-center mx-0">
              <img
                className="citizen-logo"
                src={NewsBackground}
                alt="Citizen"
              />
            </div>
            <div className="col-md-6 d-flex flex-row align-items-center justify-content-center mx-0">
              <div class="card w-100">
                <div class="card-body">
                  <h4 class="card-title">Rest Password</h4>
                  <div class="form-group">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label htmlFor="password">New Password *</label>
                        <input
                          type="password"
                          class="form-control"
                          name="password"
                          id="password"
                          aria-describedby="helpId"
                          placeholder="New Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label htmlFor="password">Confirm New Password *</label>
                        <input
                          type="password"
                          class="form-control"
                          name="repassword"
                          id="repassword"
                          aria-describedby="helpId"
                          placeholder="Confirm New Password"
                          value={this.state.repassword}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-12">
                        {this.state.isError ? (
                          <div
                            className="alert alert-danger w-100"
                            role="alert"
                          >
                            {this.state.errorMsg}
                          </div>
                        ) : this.state.spinner ? (
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                              <div
                                className="spinner-border text-danger"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        ) : this.state.isValid ? (
                          <div className="alert alert-success" role="alert">
                            {this.state.validMsg}
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-block text-white"
                            onClick={this.handleClick}
                            style={{ backgroundColor: "#CD3235" }}
                          >
                            Send
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </di>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resetState: state.appStore.reset,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => dispatch(resetPassword(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
