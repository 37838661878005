import React, { Fragment, PureComponent } from "react";
import NewsBackground from "../../img/news-cuate.svg";
// import GooglePlay from "../../img/GooglePlay.svg";
// import AppleStore from "../../img/AppleStore.svg";
// import calender from "../../img/calender.svg";
// import clock from "../../img/clock.svg";
import moment from "moment";

import { registerUser } from "../../store/actions/app-state-action";
import { connect } from "react-redux";
class Register extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      repassword: "",
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.registerState !== prevProps.registerState) {
      if (
        this.props.registerState.success &&
        !this.props.registerState.isLoading
      ) {
        window.localStorage.setItem("login", true);
        this.showSuccess();
      } else if (
        this.props.registerState.isError &&
        !this.props.registerState.isLoading
      ) {
        this.setState({ ...this.state, spinner: false });
        this.showError(this.props.registerState.message);
      }
    }
  }
  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  isNumeric = (str) => {
    if (typeof str !== "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  hasSpecialChar = (s) => {
    var format = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);
    if (format.test(s)) {
      return false;
    } else {
      return true;
    }
  };

  hasWhiteSpace = (s) => {
    var reWhiteSpace = new RegExp(/\s/);

    // Check for white space
    if (reWhiteSpace.test(s)) {
      //alert("Please Check Your Fields For Spaces");
      return false;
    }

    return true;
  };

  passwordStrength = () => {
    var pattern = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );

    if (!pattern.test(this.state.password)) {
      return false;
    }
    return true;
  };

  handleClick = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
    );

    if (this.state.firstName.length === 0) {
      this.showError("First Name is empty!");
      return false;
    }

    if (this.state.lastName.length === 0) {
      this.showError("Last Name is empty!");
      return false;
    }

    if (this.state.email.length === 0) {
      this.showError("Email is empty!");
      return false;
    }
    if (this.state.phone.length === 0) {
      this.showError("Phone Number is empty!");
      return false;
    }
    if (this.state.password.length === 0) {
      this.showError("Password is empty!");
      return false;
    }
    if (this.state.repassword.length === 0) {
      this.showError("Confirm Password is empty!");
      return false;
    }

    if (
      this.state.firstName.length === 0 ||
      this.state.lastName.length === 0 ||
      this.state.password.length === 0 ||
      this.state.email.length === 0 ||
      this.state.phone.length === 0 ||
      this.state.repassword.length === 0
    ) {
      this.showError("Required fields are empty!");
    } else {
      if (
        !this.hasWhiteSpace(this.state.firstName) ||
        !this.hasSpecialChar(this.state.firstName)
      ) {
        this.showError(
          "First Name cannot contain white spaces or special characters!"
        );
        return false;
      }

      if (
        !this.hasWhiteSpace(this.state.lastName) ||
        !this.hasSpecialChar(this.state.lastName)
      ) {
        this.showError(
          "Last Name cannot contain white spaces or special characters!"
        );
        return false;
      }

      if (!this.hasWhiteSpace(this.state.password)) {
        this.showError("Password cannot contain white spaces!");
        return false;
      }

      if (this.state.phone.replace(/ /g, "").length !== 10) {
        this.showError("Phone Number must have 10 digits!");
        return false;
      }
      if (!this.isNumeric(this.state.phone)) {
        this.showError("Phone Number contains non-numeric values!");
        return false;
      }

      if (!this.passwordStrength()) {
        this.showError(
          "Password must container minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character!"
        );
        return false;
      }

      if (this.state.password !== this.state.repassword) {
        this.showError("Password is not matching with confirm password!");
      } else if (!pattern.test(this.state.email)) {
        this.showError("Invalid email format!");
      } else {
        var today = new Date();
        this.props.registerUser({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone,
          isSocial: 0,
          dateCreated: moment(today).format("YYYY-MM-DD"),
          password: this.state.password,
        });
        this.setState({ ...this.state, spinner: true });
      }
    }
  };

  handleLogin = () => {
    // this.props.history.push("/ta/");
    window.top.location.href = `/`;
  };

  showSuccess = () => {
    this.setState(
      {
        ...this.state,
        validMsg: "Account created successfully!",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          // this.props.history.push("/");
          window.top.location.href = `/`;
        }, 2000);
      }
    );
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  };

  render() {
    return (
      <Fragment>
        <div className="login-wrapper">
          <div className="row mx-0">
            <div className="col-md-6 d-flex flex-row align-items-center justify-content-center mx-0">
              <img
                className="citizen-logo"
                src={NewsBackground}
                alt="Citizen"
              />
            </div>
            <div className="col-md-6 d-flex flex-row align-items-center mx-0">
              <div className="card">
                <div className="card-body">
                  <p className="login-text-01">Register</p>
                  <p className="login-text-02">
                    Please fill the required fields below to register.
                  </p>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          aria-describedby="helpId"
                          placeholder="First Name"
                          required
                          value={this.state.firstName}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          aria-describedby="helpId"
                          placeholder="Last Name"
                          required
                          value={this.state.lastName}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          aria-describedby="helpId"
                          placeholder="Email"
                          required
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          aria-describedby="helpId"
                          placeholder="Phone Number"
                          required
                          value={this.state.phone}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          aria-describedby="helpId"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="repassword"
                          aria-describedby="helpId"
                          placeholder="Confirm Password"
                          value={this.state.repassword}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-3">
                      {this.state.isError ? (
                        <div className="alert alert-danger" role="alert">
                          {this.state.errorMsg}
                        </div>
                      ) : this.state.spinner ? (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : this.state.isValid ? (
                        <div className="alert alert-success" role="alert">
                          {this.state.validMsg}
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-block text-white"
                          onClick={this.handleClick}
                          style={{ backgroundColor: "#CD3235" }}
                        >
                          Register
                        </button>
                      )}
                    </div>
                    <div className="col-12 mt-3 text-center">
                      <div className="row">
                        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                          <button
                            type="button"
                            className="btn btn-block text-white"
                            style={{
                              backgroundColor: "#36528C",
                            }}
                          >
                            Register with
                            <i
                              className="fab fa-facebook-square text-white ml-3"
                              aria-hidden="true"
                              style={{
                                verticalAlign: "middle",
                                fontSize: "25px",
                              }}
                            ></i>
                          </button>
                        </div>
                        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                          <button
                            type="button"
                            className="btn btn-block google-btn"
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            Register with
                            <i
                              className="fab fa-google ml-3"
                              aria-hidden="true"
                              style={{
                                verticalAlign: "middle",
                                fontSize: "25px",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-center mt-4">
                      <p className="login-subtext">
                        Already Have an Account?&nbsp;
                        <a href="/login" target="_top">
                          Click Here
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="footer-wrapper">
          <div className="row h-50">
            <div className="col-12 d-flex justify-content-center">
              <div className="footer-login-container">
                <p className="footer-login-title text-center">
                  Download Citizen News App and Get Notified
                </p>
                <div className="row d-flex justify-content-center">
                  <img
                    className="google-store mr-2"
                    src={GooglePlay}
                    alt="GooglePlay"
                  />
                  <img
                    className="apple-store mr-2"
                    src={AppleStore}
                    alt="AppleStore"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registerState: state.appStore.register,
  };
};

const mapDispatchToProps = (dispatch) => ({
  registerUser: (data) => dispatch(registerUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
