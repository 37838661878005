import React, { Fragment, PureComponent } from "react";
// components
import NewsFeed from "./components/NewsFeed";
import VideoGallery from "./components/VideoGallery";
import LatestNews from "./components/LatestNews";
import CovidInfo from "./components/CovidInfo";
import CategoryNews from "./components/CategoryNews";
import BusinessNews from "./components/BusinessNews";

import UserStories from "./components/UserStories";
// import ViqeoPlayer from "viqeo-player-react";

import LazyLoad from "react-lazyload";

import "./components/index.css";
import { withRouter } from "react-router-dom";
class Home extends PureComponent {
  render() {
    return (
      <Fragment>
        <LazyLoad height={100} once={true}>
          <UserStories />
        </LazyLoad>
        <LazyLoad height={200} once={true}>
          <NewsFeed />
        </LazyLoad>
        <LazyLoad height={250} once={true} offset={100}>
          <VideoGallery />
        </LazyLoad>
        <LazyLoad height={250} once={true} offset={150}>
          <LatestNews />
        </LazyLoad>
        <LazyLoad height={150} once={true} offset={200}>
          <CovidInfo />
        </LazyLoad>
        <LazyLoad height={300} once={true} offset={250}>
          <CategoryNews />
        </LazyLoad>
        <LazyLoad height={200} once={true} offset={250}>
          <BusinessNews />
        </LazyLoad>
      </Fragment>
    );
  }
}

export default withRouter(Home);
