export const WEB_BASE_URL = "https://ta.citizen.lk";
// export const API_BASE_URL = "http://localhost/citizen-api";
// export const API_STORAGE_BASE_URL = "http://localhost/citizen-api";

// export const API_BASE_URL = "https://citizen.lk/fc-api";
// export const API_STORAGE_BASE_URL = "https://citizen.lk/fc-api";

// export const API_BASE_URL = "http://apis.codebrixtech.com";
// export const API_STORAGE_BASE_URL = "http://apis.codebrixtech.com";

// export const API_BASE_URL = "https://citizen.codebrixtech.biz/apis";
// export const API_STORAGE_BASE_URL = "https://citizen.codebrixtech.biz/apis";

export const API_BASE_URL = "https://api.citizen.lk";
export const API_STORAGE_BASE_URL = "https://api.citizen.lk";
export const API_STORAGE_BASE_THUMB_URL =
  "https://api.citizen.lk/public/thumbnail-Img/";

export const API_LOGIN = "/v1/auth/loginweb/";
export const API_REGISTER = "/v1/auth/createuser/";
export const API_GET_TOKEN = "/v1/auth/refreshToken/";

export const API_GET_ALL_POSTS = "/v1/tamilNews/posts/getAll/";
export const API_GET_POST_BY_ID = "/v1/tamilNews/posts/postBySlug/";
export const API_GET_POST_BY_CATEGORY = "/v1/tamilNews/posts/postByCategory/";
export const API_GET_POST_SEARCH = "/v1/tamilNews/posts/postSearch/";
export const API_GET_FACTCHECK_ALL = "/v1/factCheck/get-all-fact-check/";
export const API_GET_ALL_VIDEO = "/v1/videoGallery/all/";
export const API_GET_POST_BY_TAG = "/v1/tamilNews/posts/postByTag/";

export const API_GET_LIVE_NEWS = "/v1/liveVideoFeed/all/";

export const API_GET_LIVE_POST = "/v1/liveNews/get-all-live-news/";
export const API_GET_LIVE_POST_BY_ID = "/v1/liveNews/newsBySlug/";

export const API_GET_TRENDING_POSTS = "/v1/tamilNews/posts/trendingNews/";

export const API_GET_ADS = "/v1/advertisement/all/";
export const API_POST_COUNT = "/v1/advertisement/addCount/";
export const API_GET_INFO = "/v1/dashboard/all/";

export const API_ADD_VIEW_COUNT = "/v1/tamilNews/posts/addViewCount/";
export const API_GET_VIDEO_BY_CATEGORY = "/v1/videoGallery/videoByCategory/";

export const API_FORGOT_PASSWORD = "/v1/auth/forgotPassword/";
export const API_SEND_OTP = "/v1/auth/verifyCode/";
export const API_RESET_PASSWORD = "/v1/auth/resetPassword/";
