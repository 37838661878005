import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import "./index.css";
import NoImg from "../../../img/no-img.jpg";
// actions
import { getAllInfo } from "../../../store/actions/app-state-action";
import { API_STORAGE_BASE_URL } from "../../../constants/api";
class CovidInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        Title: "",
        Subtitle: "",
        Thumbnail: "",
        ThumbnailImage: "",
        Option01: "",
        Value01: "",
        Option02: "",
        Value02: "",
        Option03: "",
        Value03: "",
        Option04: "",
        Value04: "",
        Status: "0",
        DateCreated: "",
      },
      success: false,
    };
  }

  componentDidMount() {
    if (this.props.infoState.success && this.props.infoState.data) {
      this.setState({
        ...this.state,
        info: this.props.infoState.data[0]
          ? this.props.infoState.data[0]
          : this.state.info,
      });
    } else {
      this.props.getAllInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.infoState !== prevProps.infoState) {
      if (
        this.props.infoState.success &&
        this.props.infoState.data &&
        this.props.infoState.data.length !== 0
      ) {
        this.setState({
          ...this.state,
          info: this.props.infoState.data[0]
            ? this.props.infoState.data[0]
            : this.state.info,
          success: true,
        });
      }
    }
  }
  addDefaultSrc(e) {
    e.target.src = NoImg;
  }
  render() {
    // const {
    //   Title,
    //   Subtitle,
    //   Option01,
    //   Option02,
    //   Option03,
    //   Option04,
    //   Value01,
    //   Value02,
    //   Value03,
    //   Value04,
    //   Status,
    //   Thumbnail,
    // } = this.state.info;

    return (
      <Fragment>
        <div className="special-wrapper">
          {this.state.infoStatus === "1" ? (
            <div className="row mx-0">
              <div className="col-12 pl-0 pr-0">
                <div
                  className="card special-card"
                  // style={{
                  //   backgroundImage: `url(${API_STORAGE_BASE_URL + Thumbnail})`,
                  // }}
                >
                  <div className="row mx-0">
                    {/* <div className="col-12 col-md-4 col-sm-12 text-center">
                   
                    </div> */}
                    <div className="col-12 col-md-2 col-sm-12 text-center">
                      <img
                        className="dashboard-info"
                        src={API_STORAGE_BASE_URL + this.state.infoThumbnail}
                        onError={this.addDefaultSrc}
                      />
                    </div>
                    <div className="col-12 col-md-2 col-sm-12 text-center mt-2">
                      <p className="covid-case">{this.state.infoTitle}</p>
                      <p className="text-white">{this.state.infoSubtitle}</p>
                    </div>
                    <div className="col-12 col-md-2 col-sm-12 text-center covid-panel-border">
                      <p className="covid-title">{this.state.infoOption01}</p>
                      <p className="covid-text">{this.state.infoValue01}</p>
                    </div>
                    <div className="col-12 col-md-2 col-sm-12 d-flex justify-content-center covid-panel-border mx-0">
                      <div>
                        <p className="covid-title">{this.state.infoOption02}</p>
                        <p className="covid-text">{this.state.infoValue02}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-sm-12 d-flex justify-content-center covid-panel-border-02">
                      <div>
                        <p className="covid-title">{this.state.infoOption03}</p>
                        <p className="covid-text">{this.state.infoValue03}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-sm-12 d-flex justify-content-center covid-panel-border">
                      <div>
                        <p className="covid-title">{this.state.infoOption04}</p>
                        <p className="covid-text">{this.state.infoValue04}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    infoState: state.appStore.info,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllInfo: () => dispatch(getAllInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidInfo);
