import React, { Fragment, PureComponent } from "react";
import moment from "moment";
import Clock from "react-live-clock";
import "./index.css";
// Icons
import calender from "../../img/calender.svg";
import clock from "../../img/clock.svg";
import facebook from "../../img/facebook.svg";
import instagram from "../../img/instagram.svg";
import twitter from "../../img/twitter.svg";
import youtube from "../../img/youtube.svg";
import NoImg from "../../img/no-img.jpg";

// material
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import { API_STORAGE_BASE_URL, WEB_BASE_URL } from "../../constants/api";
import {
  getPostBySearch,
  getAllPosts,
} from "../../store/actions/posts-actions";

// Images
import logo from "../../img/logo.png";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class Navbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      latest: [],
      search: [],
      keyword: "",
      showSearch: false,
      isLoading: false,
      success: false,
      activeList: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      active: 10,
      errorMsg: "",
      isError: false,
    };
  }

  componentDidMount() {
    if (!this.props.postState.success) {
      this.props.getAllPosts(0, 10);
    } else {
      this.setState({
        ...this.state,
        latest: this.props.postState.data,
        success: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // if (this.props.match.params.name === "එතෙර") {
    //   this.setState({
    //     ...this.state,
    //     activeList: { ...this.state.activeList, [1]: true },
    //     active: 1,
    //   });
    // } else if (this.props.match.params.name === "කෙළි බිම") {
    //   this.setState({
    //     ...this.state,
    //     activeList: { ...this.state.activeList, [2]: true },
    //     active: 2,
    //   });
    // } else if (this.props.match.params.name === "දේශපාලන") {
    //   this.setState({
    //     ...this.state,
    //     activeList: { ...this.state.activeList, [3]: true },
    //     active: 3,
    //   });
    // } else if (this.props.match.params.name === "ආර්ථික") {
    //   this.setState({
    //     ...this.state,
    //     activeList: { ...this.state.activeList, [4]: true },
    //     active: 4,
    //   });
    // } else if (this.props.match.params.name === "මෙන්න ඇත්ත") {
    //   this.setState({
    //     ...this.state,
    //     activeList: { ...this.state.activeList, [5]: true },
    //     active: 5,
    //   });
    // } else {
    //   this.setState({
    //     ...this.state,
    //     activeList: { ...this.state.activeList, [0]: true },
    //     active: 0,
    //   });
    // }

    if (this.props.searchState !== prevProps.searchState) {
      if (this.props.searchState.success) {
        this.setState({
          ...this.state,
          search: this.props.searchState.data,
          success: true,
          isLoading: false,
        });
      }
    }

    if (this.props.postState !== prevProps.postState) {
      if (this.props.postState.success) {
        this.setState({
          ...this.state,
          latest: this.props.postState.data,
          success: true,
        });
      }
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ ...this.state, keyword: value });
  };

  handleClick = () => {
    if (this.state.keyword.length !== 0) {
      this.props.getPostBySearch(this.state.keyword);
      this.setState({ ...this.state, showSearch: true, isLoading: true });
    } else {
      this.showError("Please enter your search topic!");
    }
  };

  handleRedirect = (id) => {
    // this.props.history.push(`/article/` + id);
    window.top.location.href = `/article.php?slug=` + id;
  };

  handleCategory = (name, prev, next) => {
    this.handleCategorySwitch(prev, next);
    // this.props.history.push(`/category/` + name);
    window.top.location.href = `/category/` + name;
  };

  handleCategorySwitch = (prev, next) => {
    this.setState({
      ...this.state,
      activeList: { ...this.state.activeList, [prev]: false, [next]: true },
      active: next,
    });
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
        showSearch: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3000);
      }
    );
  };

  logout = () => {
    window.localStorage.setItem("login", false);
    // this.props.history.push("/login");
    window.top.location.href = `/`;
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  render() {
    var today = new Date();
    var isLoggedIn = window.localStorage.getItem("login");
    return (
      <Fragment>
        <div className="header-wrapper-01 d-none d-sm-block">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div className="row" style={{ alignContent: "center" }}>
                    <img className="left-icons" src={calender} alt="calender" />
                    <p className="header-text-01 icon-spacing-01 icon-spacing-02">
                      {moment(today).local("en").format("DD MMM, YYYY")}
                    </p>
                    <img className="left-icons" src={clock} alt="clock" />
                    <p className=" header-text-01 icon-spacing-01">
                      <Clock format={"hh:mm A"} ticking={true} />
                    </p>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <div className="row">
                    {/* <div className="dropdown icon-spacing-02">
                      <p
                        className="dropdown-toggle header-text-01"
                        id="triggerId"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        தமிழ்
                      </p>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="triggerId"
                      >
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                        <a className="dropdown-item disabled" href="#">
                          Disabled action
                        </a>
                        <h6 className="dropdown-header">Section header</h6>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">
                          After divider action
                        </a>
                      </div>
                    </div> */}
                    <a href={"https://si.citizen.lk/"} target="_top">
                      <button
                        type="button"
                        className="btn icon-spacing-02 header-text-01"
                      >
                        Sinhala
                      </button>
                    </a>
                    <a href={"https://citizen.lk/"} target="_top">
                      <button
                        type="button"
                        className="btn icon-spacing-02 header-text-01"
                      >
                        English
                      </button>
                    </a>
                    {isLoggedIn === "true" ? (
                      <button
                        type="button"
                        class="btn icon-spacing-02 header-text-01"
                        style={{ fontWeight: "600" }}
                        onClick={(e) => this.logout()}
                      >
                        Log Out
                      </button>
                    ) : (
                      <Fragment>
                        <a href={"https://ta.citizen.lk/login"} target="_top">
                          <button
                            type="button"
                            className="btn icon-spacing-02 header-text-01"
                            onClick={(e) =>
                              //  this.props.history.push("/login")
                              (window.top.location.href = `/login`)
                            }
                          >
                            Login
                          </button>
                        </a>
                        <a
                          href={"https://ta.citizen.lk/register"}
                          target="_top"
                        >
                          <button
                            type="button"
                            className="btn icon-spacing-02 header-text-01"
                            onClick={(e) =>
                              // this.props.history.push("/register")
                              (window.top.location.href = `/register`)
                            }
                          >
                            Register
                          </button>
                        </a>
                      </Fragment>
                    )}
                    <a
                      className="my-auto"
                      href="https://www.facebook.com/citizenlk/"
                    >
                      <img
                        className="icon-circle-01"
                        src={facebook}
                        alt="facebook"
                      />
                    </a>
                    <a
                      className="my-auto"
                      href="https://www.instagram.com/citizen.lk/"
                    >
                      <img
                        className="icon-circle-02"
                        src={instagram}
                        alt="instagram"
                      />
                    </a>
                    <a
                      className="my-auto"
                      href="https://twitter.com/citizen_newslk"
                    >
                      <img
                        className="icon-circle-02"
                        src={twitter}
                        alt="twitter"
                      />
                    </a>
                    <a
                      className="my-auto"
                      href="https://www.youtube.com/channel/UCNudBJTq-9sEGCgtL2F0AZA"
                    >
                      <img
                        className="icon-circle-02"
                        src={youtube}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-wrapper-02">
          <div className="row d-none d-sm-flex">
            <div className="col-1"></div>
            <div className="col-2 my-auto"></div>
            <div className="col-2 my-auto"></div>
            <div className="col-2 d-flex justify-content-center">
              <img
                className="header-logo"
                src={logo}
                alt="logo"
                onClick={(e) => (window.top.location.href = "/")}
              />
            </div>
            <div className="col-2 my-auto"></div>
            <div className="col-2 my-auto"></div>
            <div className="col-1 d-flex justify-content-end">
              <a
                className="text-dark my-auto"
                data-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </a>
              {/* <i className="fa fa-align-right my-auto" aria-hidden="true"></i> */}
            </div>
          </div>
        </div>
        <div className="header-wrapper-03">
          <div className="row d-flex justify-content-center">
            {/* <Link to={"/"} style={{ textDecoration: "none" }}>
              <p
                className={
                  this.state.activeList[0]
                    ? "nav-subtext-01 text-active"
                    : "nav-subtext-01"
                }
                onClick={(e) => this.handleCategorySwitch(this.state.active, 0)}
              >
                வீடு
              </p>
            </Link> */}
            <p
              className={
                this.state.activeList[5]
                  ? "nav-subtext-01 text-active"
                  : "nav-subtext-01"
              }
              onClick={(e) =>
                this.handleCategory("உள்ளூர்", this.state.active, 5)
              }
            >
              உள்ளூர்
            </p>
            <p
              className={
                this.state.activeList[1]
                  ? "nav-subtext-01 text-active"
                  : "nav-subtext-01"
              }
              onClick={(e) =>
                this.handleCategory("வெளிநாட்டு", this.state.active, 1)
              }
            >
              வெளிநாட்டு
            </p>
            <p
              className={
                this.state.activeList[2]
                  ? "nav-subtext-01 text-active"
                  : "nav-subtext-01"
              }
              onClick={(e) =>
                this.handleCategory("விளையாட்டு", this.state.active, 2)
              }
            >
              விளையாட்டு
            </p>
            <p
              className={
                this.state.activeList[3]
                  ? "nav-subtext-01 text-active"
                  : "nav-subtext-01"
              }
              onClick={(e) =>
                this.handleCategory("அரசியல்", this.state.active, 3)
              }
            >
              அரசியல்
            </p>
            <p
              className={
                this.state.activeList[4]
                  ? "nav-subtext-01 text-active"
                  : "nav-subtext-01"
              }
              onClick={(e) =>
                this.handleCategory("வணிகம்", this.state.active, 4)
              }
            >
              வணிகம்
            </p>
            {/* <p
              className={
                this.state.activeList[5]
                  ? "nav-subtext-01 text-active"
                  : "nav-subtext-01"
              }
              onClick={(e) =>
                this.handleCategory("මෙන්න ඇත්ත", 5, this.state.active, 5)
              }
            >
              මෙන්න ඇත්ත
            </p> */}
            {/* <p className="nav-subtext-02">Citizen Life</p>
            <p className="nav-subtext-02">Citizen TV</p> */}
          </div>
        </div>
        <div
          className="collapse"
          id="collapseExample"
          style={{ position: "absolute", width: "100%" }}
        >
          <div className="card search-card">
            <div className="row">
              <div className="col-12 col-sm-10 mb-3 mb-sm-0">
                <TextField
                  id="input-with-icon-textfield"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ cursor: "pointer", marginRight: "15px" }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <CloseIcon
                          data-toggle="collapse"
                          href="#collapseExample"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              keyword: "",
                              showSearch: false,
                            })
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.keyword}
                  onChange={this.handleChange}
                  placeholder="Enter your search topic"
                />
              </div>
              <div className="col-12 col-sm-2">
                <button
                  type="button"
                  className="btn text-white btn-block"
                  style={{ backgroundColor: "#cd3235" }}
                  onClick={(e) => this.handleClick()}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="row mt-3">
              {!this.state.showSearch ? (
                <Fragment>
                  <div className="col-12">
                    <div className="row mt-3">
                      <div className="col-12">
                        <p className="search-heading">THE LATEST</p>
                      </div>
                    </div>
                  </div>
                  {this.state.success ? (
                    this.state.latest
                      .map((item, index) => {
                        return (
                          <div className="col-12 col-sm-3 mt-3" key={index}>
                            <a
                              onClick={() => {
                                this.handleRedirect(item.Slug);
                              }}
                              style={{
                                color: "black",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              <div className="row">
                                <div className="col-4">
                                  <div className="search-news-circle card">
                                    <img
                                      className="search-news-circle"
                                      src={
                                        item.Thumbnail.length !== 0 &&
                                        item.Thumbnail
                                          ? API_STORAGE_BASE_URL +
                                            item.Thumbnail
                                          : NoImg
                                      }
                                      onError={this.addDefaultSrc}
                                      alt="latest news"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-8"
                                  style={{ borderRight: "1px solid #d5d7d8" }}
                                >
                                  <p className="search-news-title">
                                    {item.Title}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })
                      .slice(0, 4)
                  ) : (
                    <Fragment>
                      <div className="col-12 col-sm-3">
                        <div className="row">
                          <div className="col-4">
                            <Skeleton variant="circle" height={80} width={80} />
                          </div>
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid #d5d7d8" }}
                          >
                            <Skeleton variant="rect" height={80} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3">
                        <div className="row">
                          <div className="col-4">
                            <Skeleton variant="circle" height={80} width={80} />
                          </div>
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid #d5d7d8" }}
                          >
                            <Skeleton variant="rect" height={80} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3">
                        <div className="row">
                          <div className="col-4">
                            <Skeleton variant="circle" height={80} width={80} />
                          </div>
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid #d5d7d8" }}
                          >
                            <Skeleton variant="rect" height={80} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3">
                        <div className="row">
                          <div className="col-4">
                            <Skeleton variant="circle" height={80} width={80} />
                          </div>
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid #d5d7d8" }}
                          >
                            <Skeleton variant="rect" height={80} />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <div className="search-list-container">
                  {this.state.isError ? (
                    <div className="alert alert-danger" role="alert">
                      {this.state.errorMsg}
                    </div>
                  ) : !this.state.isLoading ? (
                    this.state.search.length > 0 ? (
                      this.state.search.map((item, index) => {
                        return (
                          <a
                            key={index}
                            onClick={() => {
                              this.handleRedirect(item.Slug);
                            }}
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="row"
                              data-toggle="collapse"
                              href="#collapseExample"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <div className="col-12">
                                <div className="row mx-0">
                                  <div className="col-3 col-sm-2">
                                    <div className="news-img-container">
                                      <img
                                        className="trending-img"
                                        src={
                                          item.Thumbnail.length !== 0 &&
                                          item.Thumbnail
                                            ? API_STORAGE_BASE_URL +
                                              item.Thumbnail
                                            : NoImg
                                        }
                                        onError={this.addDefaultSrc}
                                        alt="news"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-9 col-sm-10">
                                    <p className="trending-news-text">
                                      {item.Title}
                                    </p>
                                    <p>
                                      <p className="trending-news-subtext my-auto">
                                        {moment(item.DateCreated).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })
                    ) : this.state.keyword.length === 0 ? null : (
                      <p className="text-center">Search Result Not Found!</p>
                    )
                  ) : (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchState: state.postStore.search,
    latestNewsState: state.postStore.posts,
    postState: state.postStore.posts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostBySearch: (data) => dispatch(getPostBySearch(data)),
  getAllPosts: (from, to) => dispatch(getAllPosts(from, to)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
