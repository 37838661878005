import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_AUTH_LOGIN,
  ACTION_AUTH_REGISTER,
  ACTION_GET_TOKEN,
  ACTION_GET_ADS,
  ACTION_ADD_COUNT,
  ACTION_GET_ALL_INFO,
  ACTION_ADD_VIEW_COUNT,
  ACTION_FORGOT_PASSWORD,
  ACTION_RESET_PASSWORD,
  ACTION_SEND_OTP,
} from "../../constants/action";
const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const loginUser = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_AUTH_LOGIN, data: {} };
  dispatch(actionData);
  await AppServices.UserLogin(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const registerUser = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_AUTH_REGISTER, data: {} };
  dispatch(actionData);
  await AppServices.UserRegister(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: res.data.status !== 1 ? true : false,
        message: res.data.message,
        success: res.data.status === 1 ? true : false,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getRefreshToken = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_TOKEN, data: {} };
  dispatch(actionData);
  await AppServices.getToken(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getAllAds = () => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_ADS, data: {} };
  dispatch(actionData);
  await AppServices.getAllAds()
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const addCount = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_ADD_COUNT, data: {} };
  dispatch(actionData);
  await AppServices.addCount(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getAllInfo = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_ALL_INFO, data: {} };
  dispatch(actionData);
  await AppServices.getAllInfo(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const addViewCount = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_ADD_VIEW_COUNT, data: {} };
  dispatch(actionData);
  await AppServices.addViewCount(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const forgotPassword = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_FORGOT_PASSWORD, data: {} };
  dispatch(actionData);
  await AppServices.forgotPassword(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const resetPassword = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_RESET_PASSWORD, data: {} };
  dispatch(actionData);
  await AppServices.resetPassword(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const sendOTP = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_SEND_OTP,
    data: {},
    status: 0,
  };
  dispatch(actionData);
  await AppServices.sendOTP(data)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
