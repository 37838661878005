import React, { Fragment, PureComponent } from "react";
import NoImg from "../../../img/no-img.jpg";

import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Skeleton from "@material-ui/lab/Skeleton";

import { connect } from "react-redux";
import { getTrendingPosts } from "../../../store/actions/posts-actions";
import { withRouter } from "react-router-dom";
import {
  API_STORAGE_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../../constants/api";
import moment from "moment";
class TrendingToday extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      trending: [],
      success: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.postCategoryState !== prevProps.postCategoryState) {
      if (this.props.postCategoryState.success) {
        this.setState({
          ...this.state,
          trending: this.props.postCategoryState.data,
          success: true,
        });
      }
    }
  }

  componentDidMount() {
    if (
      !this.props.postCategoryState ||
      !this.props.postCategoryState.success
    ) {
      this.props.getTrendingPosts(0); //Current News
    } else {
      this.setState({
        ...this.state,
        trending: this.props.postCategoryState.data,
        success: true,
      });
    }
  }

  handleRedirect = (id) => {
    // this.props.history.push(`/article/` + id);
    window.top.location.href = `/article.php?slug=` + id;
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }
  addImgErrorDefaultSrc = (e, item) => {
    e.target.src =
      item.length !== 0 && item ? API_STORAGE_BASE_URL + item : NoImg;
  };

  render() {
    const items = [1, 2, 3, 4, 5, 6];
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="card trending-news-card h-100">
              <div className="card-body">
                <p
                  className="trending-news-title"
                  style={{
                    fontFamily: "'Inter', sans-serif",
                  }}
                >
                  Trending News
                </p>
                {this.state.success ? (
                  this.state.trending &&
                  this.state.trending.length !== 0 &&
                  this.state.trending
                    .map((item, index) => {
                      var thumbnail = "";
                      if (item.Thumbnail) {
                        var getImagePath = item.Thumbnail.split("/");
                        var thumb = getImagePath[3].split(".");
                        thumbnail = thumb[0];
                      }
                      return (
                        <Fragment key={index}>
                          <div
                            className="row mb-1 pt-2"
                            onClick={(e) => this.handleRedirect(item.Slug)}
                          >
                            <div className="col-8 ">
                              <p className="trending-news-text">{item.Title}</p>
                              <div className="row pl-3 pr-3 mb-3">
                                <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                <p className="trending-news-subtext my-auto">
                                  {moment(item.DateCreated)
                                    .local()
                                    .startOf("seconds")
                                    .fromNow()}
                                </p>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="news-img-container placeholder-img">
                                <img
                                  className="trending-img"
                                  src={
                                    thumbnail.length !== 0 && thumbnail
                                      ? API_STORAGE_BASE_THUMB_URL +
                                        thumbnail +
                                        ".jpeg"
                                      : item.Thumbnail.length !== 0 &&
                                        item.Thumbnail
                                      ? API_STORAGE_BASE_URL + item.Thumbnail
                                      : NoImg
                                  }
                                  onError={(e) => {
                                    this.addImgErrorDefaultSrc(
                                      e,
                                      item.Thumbnail
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })
                    .slice(0, 6)
                ) : (
                  <Fragment>
                    {items.map((item) => {
                      return (
                        <div className="row mb-2" key={item}>
                          <div className="col-8 ">
                            <Skeleton variant="rect" height={40} />
                            <div className="row pl-3 pr-3 mb-3 mt-2">
                              <p className="trending-news-subtext my-auto">
                                <Skeleton
                                  variant="rect"
                                  height={14}
                                  width={76}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="news-img-container">
                              <Skeleton variant="rect" height={60} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postCategoryState: state.postStore.trendingPosts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTrendingPosts: (value) => dispatch(getTrendingPosts(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrendingToday)
);
