import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_ALL_POSTS,
  ACTION_GET_POST_BY_ID,
  ACTION_GET_POST_BY_CATEGORY,
  ACTION_GET_POST_SEARCH,
  ACTION_GET_POST_BY_SEARCH,
  ACTION_GET_FACTCHECK_ALL,
  ACTION_GET_POST_BY_TAG,
  ACTION_GET_TRENDING_POSTS,
} from "../../constants/action";

const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getAllPosts = (from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_POSTS,
    data: {},
    status: false,
  };
  // dispatch(actionData);
  await AppServices.getAllPosts(from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPostById = (name) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_POST_BY_ID, data: {} };
  dispatch(actionData);
  await AppServices.getPostById(name)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPostByCategory = (data, from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_POST_BY_CATEGORY,
    data: [],
    key: data,
    status: false,
  };
  dispatch(actionData);
  await AppServices.getPostByCategory(data, from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        key: data,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPost = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_POST_SEARCH,
    data: {},
  };
  dispatch(actionData);
  await AppServices.getPost(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPostBySearch = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_POST_BY_SEARCH,
    data: {},
  };
  await AppServices.getPostBySearch(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getAllFactCheck = (from, to, language) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_FACTCHECK_ALL,
    data: {},
  };
  await AppServices.getFactCheckAll(from, to, language)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getPostByTag = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_POST_BY_TAG,
    data: {},
  };
  await AppServices.getPostByTag(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getTrendingPosts = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_TRENDING_POSTS,
    data: {},
  };
  await AppServices.getTrendingPosts(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
