import React, { Fragment, PureComponent } from "react";
// import VideoImg from "../../../img/VideoImg.png";
import play_circle from "../../../img/play_circle.svg";
import NoImg from "../../../img/no-img.jpg";

import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Skeleton from "@material-ui/lab/Skeleton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import calender from "../../../img/calender.svg";
import { connect } from "react-redux";
import { getPostByCategory } from "../../../store/actions/posts-actions";
import { getAllVideo } from "../../../store/actions/video-state-action";
import { addCount } from "../../../store/actions/app-state-action";
import {
  API_STORAGE_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../../constants/api";
import moment from "moment";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

class BusinessNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      business: [],
      foreign: [],
      isBusiness: false,
      isForeign: false,
      success: false,
      videoList: [],
      open: false,
      videoId: 0,
      adList: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.postCategoryState !== prevProps.postCategoryState) {
      if (this.props.postCategoryState["வணிகம்"].success) {
        this.setState({
          ...this.state,
          business: this.props.postCategoryState["வணிகம்"].data,
          isBusiness: true,
        });
      }
    }
    if (this.props.videoState !== prevProps.videoState) {
      if (this.props.videoState.success) {
        this.setState({
          ...this.state,
          videoList: this.props.videoState.data,
          success: true,
        });
      }
    }

    if (prevProps.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
  }

  componentDidMount() {
    this.props.getAllVideo(0, 10);
    if (
      !this.props.postCategoryState["வணிகம்"] ||
      !this.props.postCategoryState["வணிகம்"].success
    ) {
      this.props.getPostByCategory("வணிகம்", 0, 10); //business
    } else {
      this.setState({
        ...this.state,
        business: this.props.postCategoryState["வணிகம்"].data,
        isBusiness: true,
      });
    }
  }

  handleRedirect = (id) => {
    // this.props.history.push(`/article/` + id);
    window.top.location.href = `/article.php?slug=` + id;
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (index) => {
    this.setState({ ...this.state, open: true, videoId: index });
  };

  handleCategory = (name, id) => {
    // this.props.history.push(`/category/` + name);
    window.top.location.href = `/category/` + name;
  };

  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.top.location.href = url;
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  addImgErrorDefaultSrc = (e, item) => {
    e.target.src =
      item.length !== 0 && item ? API_STORAGE_BASE_URL + item : NoImg;
  };

  render() {
    const items = [1, 2, 3, 4, 5, 6];
    return (
      <Fragment>
        <div className="business-news-wrapper">
          <div className="row mx-0">
            <div className="col-12">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 05") {
                    return (
                      <div className="ad2 mb-2" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail}
                          alt="advertisement"
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          onError={this.addDefaultSrc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
            <div className="col-12 col-md-4 pl-0 pr-0 pr-sm-3 my-2">
              {/* <div className="row mx-0">
                
              </div> */}
              <div
                className="card video-gallery-list-card"
                style={{
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                <div className="card-body">
                  <p className="video-gallery-list-title">Videos</p>
                  <div className="row mx-0">
                    {this.state.success && this.state.videoList.length > 0 ? (
                      this.state.videoList
                        .map((item, index) => {
                          var url_string = item.URL; //window.location.href
                          // var url = new URL(url_string);
                          // var id = url.searchParams.get("v");
                          var url = new URL(url_string).pathname;
                          // console.log("test", new URL(url_string).pathname);
                          // id = url.searchParams.get("v");
                          var id = url.replace("/", "");
                          return (
                            <div
                              className="col-12 mt-4"
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={(e) => this.handleOpenModal(index)}
                            >
                              <div className="video-gallery-image-container">
                                <img
                                  className="business-video"
                                  src={`https://img.youtube.com/vi/${id}/0.jpg`}
                                  alt="VideoImg"
                                />
                              </div>
                              <div className="video-info-container">
                                <p className="video-info-date">
                                  <img
                                    className="calendar-icon"
                                    src={calender}
                                    alt="calendar"
                                  />
                                  {moment(item.DateCreated).format(
                                    "YYYY-MM-DD"
                                  )}
                                </p>
                                <p className="video-info-text">{item.Title}</p>
                              </div>
                              <button
                                type="button"
                                className="btn video-length"
                              >
                                2:39
                              </button>
                              <div className="play-btn d-flex justify-content-center">
                                <img
                                  className="play-img"
                                  src={play_circle}
                                  alt="play"
                                />
                              </div>
                            </div>
                          );
                        })
                        .slice(0, 3)
                    ) : (
                      <Fragment>
                        <div className="col-12 mt-4">
                          <Skeleton variant="rect" height={180} />
                        </div>
                        <div className="col-12 mt-4">
                          <Skeleton variant="rect" height={180} />
                        </div>
                        <div className="col-12 mt-4">
                          <Skeleton variant="rect" height={180} />
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 pl-0 pr-0 pl-sm-3 pr-sm-3 pr-md-0 my-2 mx-0">
              <div class="card video-gallery-list-card h-100">
                <div class="card-body">
                  <div className="row mx-0">
                    <div className="col-12 align-self-end mt-3 mt-sm-0">
                      <div className="row">
                        <div className="col-6">
                          <button type="button" className="btn business-button">
                            வணிகம்
                          </button>
                        </div>
                        <div className="col-6 text-right my-auto">
                          <p
                            className="foreign-text-01"
                            onClick={(e) => this.handleCategory("வணிகம்", 2)}
                          >
                            View More
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center mx-0">
                    {this.state.isBusiness ? (
                      this.state.business.length !== 0 ? (
                        <Fragment>
                          {this.state.business
                            .map((item, index) => {
                              var thumbnail = "";
                              if (item.Thumbnail) {
                                var getImagePath = item.Thumbnail.split("/");
                                var thumb = getImagePath[3].split(".");
                                thumbnail = thumb[0];
                              }
                              return (
                                <Fragment key={index}>
                                  <div
                                    className="col-12 col-sm-4 mt-3"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      this.handleRedirect(item.Slug)
                                    }
                                  >
                                    <div className="card latest-news-card h-100">
                                      <div className="latest-news-img-container placeholder-img">
                                        <img
                                          className="latest-news-img"
                                          src={
                                            thumbnail.length !== 0 && thumbnail
                                              ? API_STORAGE_BASE_THUMB_URL +
                                                thumbnail +
                                                ".jpeg"
                                              : item.Thumbnail.length !== 0 &&
                                                item.Thumbnail
                                              ? API_STORAGE_BASE_URL +
                                                item.Thumbnail
                                              : NoImg
                                          }
                                          onError={(e) => {
                                            this.addImgErrorDefaultSrc(
                                              e,
                                              item.Thumbnail
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="card-body p-3">
                                        <button
                                          type="button"
                                          className="btn btn-sm business-news-button"
                                        >
                                          வணிகம்
                                        </button>
                                        <div className="row pl-3 pr-3 latest-news-subtext">
                                          <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                          <p className="ln-text-date">
                                            {moment(item.DateCreated)
                                              .local()
                                              .startOf("seconds")
                                              .fromNow()}
                                          </p>
                                        </div>
                                        <p className="ln-text-01">
                                          {item.Title.length > 60
                                            ? item.Title.slice(0, 60) + "..."
                                            : item.Title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            })
                            .slice(0, 4)}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="row d-flex justify-content-center">
                            <p>There are no articles.</p>
                          </div>
                        </Fragment>
                      )
                    ) : (
                      <Fragment>
                        {items
                          .map((item, index) => {
                            return (
                              <div
                                className="col-12 col-sm-4 pl-0 mt-3"
                                key={index}
                              >
                                <div className="card latest-news-card">
                                  <div className="latest-news-img-container">
                                    <Skeleton variant="rect" height={189} />
                                  </div>
                                  <div className="card-body p-3">
                                    <div className="row pl-3 pr-3 mb-2">
                                      <Skeleton
                                        variant="rect"
                                        width={110}
                                        height={18}
                                      />
                                    </div>
                                    <Skeleton variant="rect" height={40} />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          .slice(0, 4)}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div style={{ width: "75%", height: "75%" }}>
              {this.state.success && this.state.videoList.length !== 0 ? (
                <ReactPlayer
                  controls={true}
                  width="100%"
                  height="100%"
                  url={this.state.videoList[this.state.videoId].URL}
                />
              ) : null}
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.postStore.posts,
    postCategoryState: state.postStore.category,
    videoState: state.videoStore.video,
    adState: state.appStore.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostByCategory: (key, from, to) =>
    dispatch(getPostByCategory(key, from, to)),
  getAllVideo: (from, to) => dispatch(getAllVideo(from, to)),
  addCount: (data) => dispatch(addCount(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessNews)
);
