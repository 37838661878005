import React, { PureComponent, Fragment } from "react";
import stroriesLogo from "../../../img/stroriesLogo.png";
import { getAllAds, addCount } from "../../../store/actions/app-state-action";
import { connect } from "react-redux";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  API_STORAGE_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../../constants/api";
import { withRouter } from "react-router-dom";
class UserStories extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      adList: [],
    };
  }

  componentDidMount() {
    this.props.getAllAds();
    const script = document.createElement("script");
    script.src = "https://cdn.viqeo.tv/js/vq_starter.js";
    script.async = true;
    // script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
  }

  componentDidUpdate(prevState) {
    if (prevState.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
  }
  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.top.location.href = url;
  };

  render() {
    return (
      <Fragment>
        <div className="news-feed-wrapper">
          <div className="row mx-0">
            <div className="col-12 order-2 order-sm-1 p-sm-0">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 01") {
                    return (
                      <div className="ad mt-4 mb-4 ad-md-banner" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail.toString()}
                          alt="Section 01"
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          style={{ cursor: "pointer" }}
                          onError={this.addDefaultSrc}
                        />
                      </div>
                    );
                  }
                })}

              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 06") {
                    return (
                      <Fragment key={index}>
                        <div className="card article-ad-right ad-sm-banner">
                          <img
                            src={
                              API_STORAGE_BASE_URL + item.Thumbnail.toString()
                            }
                            alt="advertisement"
                            onClick={(e) =>
                              this.handleCount(item.Id, item.Count, item.Url)
                            }
                            onError={this.addDefaultSrc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Fragment>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        {/* <div className="story-container">
          <div className="row mt-4 mb-4">
            <div className="col-12 col-sm-2 col-xl-1">
              <img
                className="d-none d-sm-block"
                src={stroriesLogo}
                alt="story logo"
              />
            </div>
            <div className="col-12 col-sm-10 col-xl-11">
              <div
                data-playerid="1005"
                data-profileid="2750"
                className="viqeo-story-previews-widget"
              ></div>
            </div>
          </div>
        </div> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adState: state.appStore.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllAds: () => dispatch(getAllAds()),
  addCount: (data) => dispatch(addCount(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserStories)
);
