import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { forgotPassword, sendOTP } from "../../store/actions/app-state-action";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import NewsBackground from "../../img/news-cuate.svg";
class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      success: false,
      open: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
      code: "",
      modalSpinner: false,
      isVerified: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.forgotState !== prevProps.forgotState) {
      if (this.props.forgotState.success) {
        this.setState({ ...this.state, spinner: false }, () => {
          this.handleOpenModal();
        });
      } else if (this.props.forgotState.isError) {
        this.showError("Failed to verify email!");
      }
    }

    if (this.props.codeState !== prevProps.codeState) {
      if (this.props.codeState.success) {
        this.setState({ ...this.state, modalSpinner: false }, () => {
          this.handleCloseModal();
          // this.props.history.push("/resetPassword");
          window.top.location.href = `/resetPassword`;
        });
      } else if (this.props.codeState.isError) {
        this.showError("Failed to verify email!");
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  handleClick = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (this.state.email.length === 0) {
      this.showError("Email cannot be empty!");
      return false;
    } else if (!pattern.test(this.state.email)) {
      this.showError("Invalid email format!");
      return false;
    } else {
      this.setState({ ...this.state, spinner: true });
      window.sessionStorage.setItem("email", this.state.email);
      this.props.forgotPassword({
        email: this.state.email,
      });
    }
  };

  submitOTP = () => {
    if (this.state.code.length !== 0) {
      this.setState({ ...this.state, modalSpinner: true });
      this.props.sendOTP({
        email: this.state.email,
        code: this.state.code,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = () => {
    this.setState({ ...this.state, open: true });
  };

  showSuccess = (msg) => {
    this.setState(
      {
        ...this.state,
        validMsg: msg,
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          // this.props.history.push("/login");
          window.top.location.href = `/login`;
        }, 2000);
      }
    );
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 2500);
      }
    );
  };

  render() {
    return (
      <Fragment>
        <div className="login-wrapper">
          <div className="row mx-0">
            <div className="col-md-6 d-flex flex-row align-items-center justify-content-center mx-0">
              <img
                className="citizen-logo"
                src={NewsBackground}
                alt="Citizen"
              />
            </div>
            <div className="col-md-6 d-flex flex-row align-items-center mx-0">
              <div class="card w-100">
                <div class="card-body">
                  <p className="login-text-01">Forgot Password</p>
                  <p class="card-text">
                    Enter your email below to reset your password.
                  </p>
                  <div class="form-group">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label htmlFor="email">Email *</label>
                        <input
                          type="text"
                          class="form-control"
                          name="email"
                          id="email"
                          aria-describedby="helpId"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-12">
                        {this.state.isError ? (
                          <div
                            className="alert alert-danger w-100"
                            role="alert"
                          >
                            {this.state.errorMsg}
                          </div>
                        ) : this.state.spinner ? (
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                              <div
                                className="spinner-border text-danger"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        ) : this.state.isValid ? (
                          <div className="alert alert-success" role="alert">
                            {this.state.validMsg}
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-block text-white"
                            onClick={this.handleClick}
                            style={{ backgroundColor: "#CD3235" }}
                          >
                            Send
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div class="card" style={{ width: "50%" }}>
              <div class="card-body">
                <h4>Email Verification</h4>
                {this.state.modalSpinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <p>
                      We have sent you OTP code to your mail, please enter it
                      below to reset your password
                    </p>
                    <div className="row">
                      <div className="col-12">
                        <label for="code">OTP Code *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="code"
                          placeholder="OTP Code"
                          value={this.state.code}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-12 d-flex justify-content-end mt-3">
                        <button
                          type="button"
                          className="btn text-white"
                          onClick={this.submitOTP}
                          style={{ backgroundColor: "#CD3235" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forgotState: state.appStore.forgot,
    codeState: state.appStore.code,
  };
};

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (data) => dispatch(forgotPassword(data)),
  sendOTP: (data) => dispatch(sendOTP(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
