import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import Home from "../Home/home";
import Article from "../Article/index";
import CategoryView from "../Category/index";
import TagsView from "../Tags/index";
import Login from "../Login/index";
import Register from "../Register/index";
import LiveArticle from "../LiveArticle/index";
import ForgotPassword from "../ForgotPassword/index";
import ResetPassword from "../ResetPassword/index";
import PageNotFound from "../PageNotFound";
function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/article/:name" component={Article} />
      <Route exact path="/category/:name" component={CategoryView} />
      <Route exact path="/tags/:name" component={TagsView} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/live-article/:id" component={LiveArticle} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <Route exact path="/resetPassword" component={ResetPassword} />
      <Route path="*" exact={true} component={PageNotFound} />
    </Switch>
  );
}

export default withRouter(Routes);
