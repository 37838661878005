import React, { Fragment, PureComponent } from "react";
import "./index.css";

// images
import GooglePlay from "../../img/GooglePlay.jpg";
import AppleStore from "../../img/AppleStore.jpg";
import FooterLogo from "../../img/logo.png";

// Icons
import facebook02 from "../../img/facebook02.svg";
import instagram from "../../img/instagram.svg";
import twitter from "../../img/twitter.svg";
import youtube from "../../img/youtube.svg";
import { withRouter } from "react-router";
import WhatsAppImg from "../../img/whatsApp1.png";

class Footer extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleCategory = (name) => {
    // this.props.history.push(`/category/` + name);
    window.top.location.href = `/category/` + name;
  };

  render() {
    return (
      <Fragment>
        <div className="footer-bg-01">
          <div className="row d-flex justify-content-center">
            <div className="footer-container-01">
              <p className="footer-title-01 text-center">
                Download Citizen News App and Get Notified
              </p>
              <div className="row d-flex justify-content-center">
                <a
                  href="https://play.google.com/store/apps/details?id=lk.bhasha.citizen&hl=en&gl=US"
                  target="_blank"
                >
                  <img
                    className="store-img-01"
                    src={GooglePlay}
                    alt="GooglePlay"
                  />
                </a>
                <img
                  className="store-img-02"
                  src={AppleStore}
                  alt="AppleStore"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bg-02">
          <div className="row">
            <div className="col-12 col-sm-3">
              <img className="footer-logo" src={FooterLogo} alt="logo" />
              <p className="footer-text-01 mt-4">
                Digital Content Private Limited <br /> No 96/8, Kottanuwatta
                Road, Nugegoda, Sri Lanka
              </p>
              <p className="footer-text-02 pt-4">
                <i
                  className="fas fa-envelope pr-3 text-white"
                  aria-hidden="true"
                ></i>
                news@citizen.lk
              </p>
              <p className="footer-text-02">
                <i
                  className="fa fa-phone pr-3 text-white"
                  aria-hidden="true"
                ></i>
                Tel: 011 281 0532
              </p>
              <p className="footer-text-02">
                <i
                  className="fa fa-phone pr-3 text-white"
                  aria-hidden="true"
                ></i>
                Tel: 076 441 2285
              </p>
            </div>
            <div className="col-12 col-sm-2"></div>
            <div className="col-6 col-sm-2 pt-4">
              <p className="footer-title-02">Categories</p>
              <div className="row footer-text-01 mt-4">
                {/* <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("சமீபத்திய")}
                    style={{ cursor: "pointer" }}
                  >
                    சமீபத்திய
                  </p>
                </div> */}
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("வெளிநாட்டு")}
                    style={{ cursor: "pointer" }}
                  >
                    வெளிநாட்டு
                  </p>
                </div>
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("விளையாட்டு")}
                    style={{ cursor: "pointer" }}
                  >
                    விளையாட்டு
                  </p>
                </div>
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("அரசியல்")}
                    style={{ cursor: "pointer" }}
                  >
                    அரசியல்
                  </p>
                </div>
                <div className="col-12">
                  <p
                    onClick={(e) => this.handleCategory("வணிகம்")}
                    style={{ cursor: "pointer" }}
                  >
                    வணிகம்
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-2 pt-4">
              <p className="footer-title-02">Citizen News</p>
              <div className="row footer-text-01 mt-4">
                {/* <div className="col-12">
                  <p>Citizen Life</p>
                </div>
                <div className="col-12">
                  <p>Citizen TV</p>
                </div> */}
                <div className="col-12">
                  <a
                    href="https://fc.citizen.lk/"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <p>Fact Check</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3 pt-4">
              <p className="footer-title-02">Social Links</p>
              <div className="row">
                <a
                  className="my-auto"
                  href="https://www.facebook.com/citizenlk/"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={facebook02}
                    alt="facebook02"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://www.instagram.com/citizen.lk/"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={instagram}
                    alt="instagram"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://twitter.com/citizen_newslk"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={twitter}
                    alt="twitter"
                  />
                </a>
                <a
                  className="my-auto"
                  href="https://www.youtube.com/channel/UCNudBJTq-9sEGCgtL2F0AZA"
                >
                  <img
                    className="footer-icon-circle-01 my-auto"
                    src={youtube}
                    alt="youtube"
                  />
                </a>
              </div>
            </div>
          </div>

          <hr
            style={{
              border: "1px solid rgba(255, 255, 255, 1)",
              backgroundColor: "white",
            }}
          />
          <div className="row mx-0">
            <div className="col-md-6 col-sm-12 d-flex justify-content-md-start justify-content-center justify-content-sm-center p-0 mx-0">
              <small className="text-white">
                © Citizen.lk All rights reserved.
              </small>
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-center justify-content-md-end justify-content-sm-center p-0 mx-0">
              <p className="footer-text-3 text-center text-white">
                Made with&nbsp;
                <i className="fa fa-heart text-danger" aria-hidden="true"></i>
                &nbsp;by&nbsp;
                <a
                  href="https://codebrixtech.com/"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  CodeBrix (Pvt) Ltd
                </a>
              </p>
            </div>
          </div>

          <div className="row mx-0">
            <div className="contact-service-div">
              <a
                href="https://chat.whatsapp.com/KILX2YqQxbyFMuBcbxycad"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <button className="whatsapp-btn d-flex align-self-center btn shadow-none">
                  <span class="text d-flex align-self-center text-center">
                    &nbsp; Activate&nbsp;<b>Free</b>&nbsp;News Alerts
                  </span>
                  <img
                    src={WhatsAppImg}
                    className="whatsapp-img button-wrapper"
                  />
                </button>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Footer);
