import React, { PureComponent } from "react";
import { Fragment } from "react";
import "./index.css";
import Citizen from "../../../img/Citizen.svg";

// material UI
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import StepConnector from "@material-ui/core/StepConnector";
import { withStyles } from "@material-ui/core/styles";

import WhatshotIcon from "@material-ui/icons/Whatshot";
import DescriptionIcon from "@material-ui/icons/Description";

// Icons
import calender from "../../../img/calender02.svg";
import time from "../../../img/time.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// actions
import { getLivePost } from "../../../store/actions/live-news-actions";
import moment from "moment-timezone";
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";
import {
  AiOutlineMore,
  AiOutlineDown,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { FaShareSquare } from "react-icons/fa";

class LiveNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      success: false,
      startIndex: 0,
      endIndex: 6,
      loadMore: true,
      loading: false,
      backbtn: false,
    };
  }

  componentDidMount() {
    if (this.props.liveState.success) {
      this.setState({
        ...this.state,
        news: this.props.liveState.data
          .filter((item) => item.IsFeatured === "1")
          .map((item, index) => ({
            ...item,
            index: index + 1,
          })),
      });
    } else {
      this.props.getLivePost(0, 1000, "Tamil");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.liveState !== prevProps.liveState) {
      if (
        this.props.liveState.success &&
        this.props.liveState.data.length !== 0
      ) {
        this.setState({
          ...this.state,
          news: this.props.liveState.data
            .filter((item) => item.IsFeatured === "1")
            .map((item, index) => ({
              ...item,
              index: index + 1,
            })),
          success: true,
        });
      }
    }
  }

  getSteps = () => {
    return ["Select campaign settings", "Create an ad group", "Create an ad"];
  };

  handleIcon = () => {
    return (
      <WhatshotIcon
        className="live-news-icons"
        style={{
          backgroundColor: "#f2af4d",
        }}
      />
    );
  };

  handleIcon2 = () => {
    return (
      <DescriptionIcon
        className="live-news-icons"
        style={{ backgroundColor: "#D4D4D4" }}
      />
    );
  };

  handleRedirect = (id) => {
    // this.props.history.push(`/live/article/` + id);
    window.top.location.href = `/live-article.php?slug=` + id;
  };

  handleSeeMore = () => {
    if (this.state.news.length >= this.state.endIndex) {
      this.setState(
        {
          ...this.state,
          loading: true,
          loadMore: true,
          backbtn: true,
          startIndex: this.state.endIndex,
          endIndex: this.state.endIndex + 6,
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              loading: false,
            });
          }, 500);
        }
      );
    }
  };
  handleBack = () => {
    this.setState(
      {
        ...this.state,
        loading: true,
        loadMore: true,
        backbtn: true,
        startIndex:
          parseInt(this.state.endIndex) - 6 == 6
            ? 0
            : parseInt(this.state.endIndex) - 12,
        endIndex: this.state.endIndex - 6,
      },
      () => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            loading: false,
            backbtn: this.state.startIndex == 6 ? true : false,
          });
        }, 500);
      }
    );
  };
  render() {
    const ColorlibConnector = withStyles({
      alternativeLabel: {
        top: 22,
      },
      active: {
        "& $line": {
          borderColor: "red",
        },
      },
      completed: {
        "& $line": {
          borderColor: "red",
        },
      },
      line: {
        borderRadius: 0,
        borderTopWidth: 0,
        lineHeight: 100,
        borderWidth: 3,
        borderColor: "#d4d4d4",
      },
    })(StepConnector);

    const override = css`
      border-color: red;
      position: absolute;
      left: 2;
    `;

    return (
      <Fragment>
        <div className="live-news-container mt-3">
          <div className="card live-news-card ">
            <div className="row ml-0 mr-0">
              <div
                className="col-md-12 d-flex flex-row align-items-center mx-0 py-md-3 py-sm-3 py-2"
                style={{
                  backgroundColor: "black",
                }}
              >
                <BounceLoader
                  color="red"
                  loading={true}
                  css={override}
                  size={25}
                />
                <p className="live-news-title ml-5 my-0">Live News</p>
                {/* <p className="badge badge-primary breaking-news mb-2 mt-2 ml-5 my-0">
                  Breaking News
                </p> */}
              </div>
            </div>
            <div className="m-0 p-0 w-100" style={{ overflowY: "auto" }}>
              <Stepper
                className="p-3 p-md-3 p-sm-2"
                orientation="vertical"
                connector={<ColorlibConnector />}
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div className="row d-flex justify-content-center">
                  <BounceLoader
                    color="red"
                    loading={this.state.loading}
                    css={override}
                    size={25}
                  />
                </div>

                {!this.state.loading &&
                  this.state.news
                    .slice(this.state.startIndex, this.state.endIndex)
                    .map((item, index) => (
                      <Step key={index} expanded={true}>
                        <StepLabel
                          StepIconComponent={
                            index === 0 ? this.handleIcon : this.handleIcon2
                          }
                        >
                          <div className="row mt-2">
                            <p
                              className="col-11 col-md-10 col-sm-9 live-news-text-01"
                              onClick={(e) => this.handleRedirect(item.Slug)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.Title.length > 60
                                ? `${item.Title.substring(0, 60)}...`
                                : item.Title}
                            </p>

                            <div className="col-1 col-md-2 col-sm-3 dropdown d-flex justify-content-end mb-4 pl-1 pr-0">
                              <button
                                className="btn p-0"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                style={{ backgroundColor: "white" }}
                              >
                                <FaShareSquare />
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div className="row d-flex justify-content-start mb-3 ml-1 mr-0 mt-0">
                                  <a
                                    target="_blank"
                                    href={
                                      "https://api.whatsapp.com/send?text=" +
                                      item.Title +
                                      "...https://ta.citizen.lk/live-article.php?slug=" +
                                      item.Title
                                    }
                                    data-action="share/whatsapp/share"
                                    style={{
                                      textDecoration: "none",
                                      color: "#000000",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn live-news-circle"
                                    >
                                      <i
                                        className="fab fa-whatsapp"
                                        aria-hidden="true"
                                        style={{ color: "#2E7D32" }}
                                      ></i>
                                    </button>
                                    WhatsApp
                                  </a>
                                </div>
                                <div className="row d-flex justify-content-start mb-3  ml-1 mr-0 mt-0">
                                  <a
                                    target="_blank"
                                    href={
                                      "https://www.facebook.com/sharer/sharer.php?u=https://ta.citizen.lk/live-article.php?slug=" +
                                      item.Title
                                    }
                                    style={{
                                      textDecoration: "none",
                                      color: "#000000",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn live-news-circle"
                                    >
                                      <i
                                        className="fab fa-facebook"
                                        aria-hidden="true"
                                        style={{ color: "#36528C" }}
                                      ></i>
                                    </button>
                                    Facebook
                                  </a>
                                </div>

                                <div className="row d-flex justify-content-start mb-3  ml-1 mr-0 mt-0">
                                  <a
                                    target="_blank"
                                    href={
                                      "http://twitter.com/share?text=" +
                                      item.Title +
                                      "&url=https://ta.citizen.lk/live-article.php?slug=" +
                                      item.Title
                                    }
                                    style={{
                                      textDecoration: "none",
                                      color: "#000000",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn live-news-circle"
                                    >
                                      <i
                                        className="fab fa-twitter"
                                        aria-hidden="true"
                                        style={{ color: "#55ACEE" }}
                                      ></i>
                                    </button>
                                    Twitter
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </StepLabel>
                        <StepContent
                          style={
                            index === 0
                              ? { borderLeft: "0.1953125vw solid #f2af4d" }
                              : { borderLeft: "0.1953125vw solid #D4D4D4" }
                          }
                        >
                          {/* <div className="row">
                            <p
                              className="col-11 col-md-10 col-sm-9 live-news-text-01"
                              onClick={(e) => this.handleRedirect(item.Slug)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.Title.length > 60
                                ? `${item.Title.substring(0, 60)}...`
                                : item.Title}
                            </p>

                            <div className="col-1 col-md-2 col-sm-3 dropdown d-flex justify-content-end mb-4 pl-1 pr-0">
                              <button
                                className="btn p-0"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                style={{ backgroundColor: "white" }}
                              >
                                <FaShareSquare />
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div className="row d-flex justify-content-start mb-3 ml-1 mr-0 mt-0">
                                  <a
                                    target="_blank"
                                    href={
                                      "https://api.whatsapp.com/send?text=" +
                                      item.Title +
                                      "...https://citizen.lk/si/article/" +
                                      item.Title
                                    }
                                    data-action="share/whatsapp/share"
                                    style={{
                                      textDecoration: "none",
                                      color: "#000000",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn live-news-circle"
                                    >
                                      <i
                                        className="fab fa-whatsapp"
                                        aria-hidden="true"
                                        style={{ color: "#2E7D32" }}
                                      ></i>
                                    </button>
                                    WhatsApp
                                  </a>
                                </div>
                                <div className="row d-flex justify-content-start mb-3  ml-1 mr-0 mt-0">
                                  <a
                                    target="_blank"
                                    href={
                                      "https://www.facebook.com/sharer/sharer.php?u=https://citizen.lk/si/article/" +
                                      item.Title
                                    }
                                    style={{
                                      textDecoration: "none",
                                      color: "#000000",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn live-news-circle"
                                    >
                                      <i
                                        className="fab fa-facebook"
                                        aria-hidden="true"
                                        style={{ color: "#36528C" }}
                                      ></i>
                                    </button>
                                    Facebook
                                  </a>
                                </div>

                                <div className="row d-flex justify-content-start mb-3  ml-1 mr-0 mt-0">
                                  <a
                                    target="_blank"
                                    href={
                                      "http://twitter.com/share?text=" +
                                      item.Title +
                                      "&url=https://citizen.lk/si/article/" +
                                      item.Title
                                    }
                                    style={{
                                      textDecoration: "none",
                                      color: "#000000",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn live-news-circle"
                                    >
                                      <i
                                        className="fab fa-twitter"
                                        aria-hidden="true"
                                        style={{ color: "#55ACEE" }}
                                      ></i>
                                    </button>
                                    Twitter
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {!this.state.backbtn &&
                          this.state.endIndex == parseInt(item.index) ? (
                            <div className="row d-flex justify-content-center">
                              <label
                                style={{ cursor: "point", color: "blue" }}
                                onClick={() => {
                                  this.handleSeeMore();
                                }}
                              >
                                See More <AiOutlineRight />
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                        </StepContent>
                      </Step>
                    ))}
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-4 d-flex justify-content-start">
                    {this.state.startIndex >= 6 && !this.state.loading ? (
                      <div className="row d-flex justify-content-center">
                        <label
                          style={{ cursor: "point", color: "blue" }}
                          onClick={() => {
                            this.handleBack();
                          }}
                        >
                          <AiOutlineLeft /> Back
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    {this.state.startIndex >= 6 &&
                    this.state.news.length >= 6 &&
                    this.state.news.length > this.state.endIndex &&
                    this.state.news.slice(
                      this.state.startIndex,
                      this.state.endIndex
                    ).length >= 6 &&
                    !this.state.loading ? (
                      <div className="row d-flex justify-content-center">
                        <label
                          style={{ cursor: "point", color: "blue" }}
                          onClick={() => {
                            this.handleSeeMore();
                          }}
                        >
                          See More <AiOutlineRight />
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-2"></div>
                </div>
              </Stepper>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    liveState: state.livePostStore.liveNewsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLivePost: (from, to, language) =>
    dispatch(getLivePost(from, to, language)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveNews)
);
