import React, { PureComponent, Fragment } from "react";
// Icon
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Skeleton from "@material-ui/lab/Skeleton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import ArticleImg from "../../img/ArticleImg.png";
// import VideoImg from "../../img/VideoImg.png";
import play_circle from "../../img/play_circle.svg";
import LiveNews from "../Home/components/LiveNews";
import TrendingToday from "../Home/components/TrendingToday";

import { connect } from "react-redux";
import { getLivePostById } from "../../store/actions/live-news-actions";
import { withRouter } from "react-router-dom";
import { getAllVideo } from "../../store/actions/video-state-action";
import { getPostByTag } from "../../store/actions/posts-actions";
import {
  addCount,
  getAllAds,
  addViewCount,
} from "../../store/actions/app-state-action";
import moment from "moment";
import "moment/locale/en-au";
import ReactPlayer from "react-player/youtube";
import NoImg from "../../img/no-img.jpg";
import { API_STORAGE_BASE_URL } from "../../constants/api";
import { AiOutlineMore } from "react-icons/ai";
import { FaShareSquare } from "react-icons/fa";
// import { Remark } from "react-remark";
import MDEditor from "@uiw/react-md-editor";
import WhatsAppIcon from "../../img/Activate_FREE_NEWS_Alerts.png";
import Swal from "sweetalert2";

class LiveArticle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      news: { Tags: [] },
      success: false,
      videoList: [],
      open: false,
      videoId: 0,
      videoSuccess: false,
      adList: [],
      show: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
    if (this.props.postState !== prevProps.postState) {
      if (
        this.props.postState.success &&
        this.props.postState.data.length !== 0
      ) {
        this.setState({
          ...this.state,
          news: this.props.postState.data[0],
          success: true,
        });
      }
    }
    if (this.props.videoState !== prevProps.videoState) {
      if (this.props.videoState.success) {
        this.setState({
          ...this.state,
          videoList: this.props.videoState.data,
          videoSuccess: true,
        });
      }
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({
        ...this.state,
        success: false,
      });
      var post = this.props.postsState.data.filter(
        (x) => x.Slug === this.props.match.params.id.toString()
      );
      if (post.length !== 0 && this.props.postsState.success) {
        this.setState({
          ...this.state,
          news: post[0],
          success: true,
        });
      } else {
        this.props.getLivePostById(this.props.match.params.id);
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("collapseExample").classList.remove("show");
    this.props.getAllVideo(0, 10);

    this.props.getAllAds();
    // this.props.getLivePostById(this.props.match.params.id);
    if (this.props.postsState.success) {
      var post = this.props.postsState.data.filter(
        (x) => x.Slug === this.props.match.params.id.toString()
      );
      if (post.length !== 0) {
        this.setState({
          ...this.state,
          news: post[0],
          success: true,
        });
      }
    } else {
      this.props.getLivePostById(this.props.match.params.id);
    }
  }

  handleRedirect = (name) => {
    // this.props.history.push(`/tags/` + name);
    window.top.location.href = `/tags` + name;
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (index) => {
    this.setState({ ...this.state, open: true, videoId: index });
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  render() {
    var id = "";
    if (this.state.videoSuccess && this.state.videoList.length > 0) {
      var url_string = this.state.videoList[0].URL; //window.location.href
      // var url = new URL(url_string);
      // id = url.searchParams.get("v");
      var url = new URL(url_string).pathname;
      id = url.replace("/", "");
    }
    {
      this.state.show &&
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "The article's URL has been copied.",
          showConfirmButton: false,
          timer: 1500,
        });
    }

    return (
      <Fragment>
        <div className="article-wrapper">
          <div className="row">
            <div className="col-12 col-sm-8 pl-3 pl-sm-0">
              <span
                className="badge badge-danger article-badge mb-3"
                style={{
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                Live News
              </span>
              {this.state.success ? (
                <p className="article-heading">{this.state.news.Title}</p>
              ) : (
                <Skeleton variant="rect" height={144} />
              )}
              <div className="row pl-3 mx-0 my-2 d-flex align-items-center">
                {this.state.success ? (
                  <div className="row mx-0">
                    <QueryBuilderIcon />
                    <p className="mb-0 pl-2 pr-3">
                      {moment(this.state.news.DateCreated)
                        .local("si")
                        .startOf("seconds")
                        .fromNow()}
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                    variant="rect"
                    height={30}
                    width={150}
                  />
                )}

                {/* {this.state.success ? (
                  <div className="row mx-0">
                    <i className="fa fa-pencil pr-2" aria-hidden="true"></i>
                    <p className="mb-0">{this.state.news.Author}</p>
                  </div>
                ) : (
                  <Skeleton
                    className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                    variant="rect"
                    height={30}
                    width={150}
                  />
                )} */}

                <div className="dropdown d-flex justify-content-center align-items-center">
                  <button
                    className="btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    style={{ backgroundColor: "white" }}
                  >
                    <FaShareSquare />
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="row d-flex justify-content-start mb-3 ml-1 mr-0 mt-0">
                      <a
                        target="_blank"
                        href={
                          "https://api.whatsapp.com/send?text=" +
                          this.state.news.Slug +
                          "..https://ta.citizen.lk/live-article.php?slug=" +
                          this.state.news.Slug
                        }
                        data-action="share/whatsapp/share"
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        <button type="button" className="btn live-news-circle">
                          <i
                            className="fab fa-whatsapp"
                            aria-hidden="true"
                            style={{ color: "#2E7D32" }}
                          ></i>
                        </button>
                        WhatsApp
                      </a>
                    </div>
                    <div className="row d-flex justify-content-start mb-3  ml-1 mr-0 mt-0">
                      <a
                        target="_blank"
                        href={
                          "https://www.facebook.com/sharer/sharer.php?u=https://ta.citizen.lk/live-article.php?slug=" +
                          this.state.news.Slug
                        }
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        <button type="button" className="btn live-news-circle">
                          <i
                            className="fab fa-facebook"
                            aria-hidden="true"
                            style={{ color: "#36528C" }}
                          ></i>
                        </button>
                        Facebook
                      </a>
                    </div>

                    <div className="row d-flex justify-content-start mb-3  ml-1 mr-0 mt-0">
                      <a
                        target="_blank"
                        href={
                          "http://twitter.com/share?text=" +
                          this.state.news.Slug +
                          "&url=https://ta.citizen.lk/live-article.php?slug=" +
                          this.state.news.Slug
                        }
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        <button type="button" className="btn live-news-circle">
                          <i
                            className="fab fa-twitter"
                            aria-hidden="true"
                            style={{ color: "#55ACEE" }}
                          ></i>
                        </button>
                        Twitter
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row mx-0">
                  <button
                    class="share-button"
                    type="button"
                    title="Share this article"
                    onClick={() => {
                      this.setState({ ...this.state, show: true }, () => {
                        navigator.clipboard.writeText(
                          "https://ta.citizen.lk/live-article.php?slug=" +
                            this.state.news.Slug
                        );

                        setTimeout(() => {
                          this.setState({ ...this.state, show: false });
                        }, 500);
                      });
                    }}
                  >
                    <svg>
                      <use href="#share-icon"></use>
                    </svg>
                    <span>
                      <i class="far fa-copy"></i> Copy Link
                    </span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="article-img-container">
                    {this.state.success ? (
                      <img
                        className="article-img"
                        src={
                          this.state.news.Thumbnail
                            ? API_STORAGE_BASE_URL + this.state.news.Thumbnail
                            : NoImg
                        }
                        onError={this.addDefaultSrc}
                      />
                    ) : (
                      <Skeleton variant="rect" height={430} />
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 article-text pr-2 pr-sm-5 pl-2 pl-sm-4">
                  {this.state.success ? (
                    <Fragment>
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.news.Article.replaceAll(
                            "font-family: un-bindumathi-regular;",
                            "font-family: Abhaya Libre, serif;"
                          ),
                        }}
                      ></div> */}
                      {/* <Remark>
                        {this.state.news.Article.replaceAll(
                          "font-family: un-bindumathi-regular;",
                          "font-family: Abhaya Libre, serif;"
                        )}
                      </Remark> */}
                      <MDEditor.Markdown
                        source={this.state.news.Article.replaceAll(
                          "font-family: un-bindumathi-regular;",
                          "font-family: Abhaya Libre, serif;"
                        )}
                        style={{
                          whiteSpace: "pre-wrap",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      />
                    </Fragment>
                  ) : (
                    <Skeleton variant="rect" height={184} />
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  {this.state.success ? (
                    <button type="button" className="btn btn-sm tag-btn">
                      <i
                        className="fa fa-tags mr-2 mt-2"
                        aria-hidden="true"
                      ></i>
                      Tags
                    </button>
                  ) : (
                    <Skeleton
                      className="btn btn-sm tag-btn-list btn-light"
                      variant="rect"
                      height={30}
                      width={75}
                    />
                  )}
                  {this.state.success ? (
                    this.state.news.Tags.length !== 0 ? (
                      this.state.news.Tags &&
                      this.state.news.Tags.sort(
                        (a, b) => parseFloat(b.count) - parseFloat(a.count)
                      ).map((item, index) => {
                        return (
                          <button
                            key={index}
                            type="button"
                            className="btn btn-sm tag-btn-list btn-light ml-3 mt-2"
                            onClick={(e) => this.handleRedirect(item)}
                          >
                            {item}
                          </button>
                        );
                      })
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm tag-btn-list btn-light ml-3 mt-2"
                      >
                        No tags present
                      </button>
                    )
                  ) : (
                    <Skeleton
                      className="btn btn-sm tag-btn-list btn-light ml-3"
                      variant="rect"
                      height={30}
                      width={75}
                    />
                  )}
                </div>
                <div className="col-12 d-flex justify-content-center mt-3">
                  <div className="whatsApp-link-option">
                    <a
                      href="https://chat.whatsapp.com/KILX2YqQxbyFMuBcbxycad"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img src={WhatsAppIcon} width="300px" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 06") {
                    return (
                      <Fragment key={index}>
                        <div className="card article-ad-right">
                          <img
                            src={API_STORAGE_BASE_URL + item.Thumbnail}
                            alt="advertisement"
                            onClick={(e) =>
                              this.handleCount(item.Id, item.Count, item.Url)
                            }
                            onError={this.addDefaultSrc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Fragment>
                    );
                  }
                })}
              <div className="row mt-4">
                <div className="col-12">
                  <div className="card">
                    <div
                      className="card-body"
                      onClick={(e) => this.handleOpenModal(0)}
                    >
                      {this.state.videoSuccess &&
                      this.state.videoList.length > 0 ? (
                        <Fragment>
                          <img
                            className="business-video"
                            src={`http://img.youtube.com/vi/${id}/0.jpg`}
                            alt="VideoImg"
                          />
                          <div className="article-video-info-container">
                            <p className="article-video-info-date">
                              <i
                                className="fa fa-calendar pr-2"
                                aria-hidden="true"
                              ></i>
                              {moment(
                                this.state.videoList[0].DateCreated
                              ).format("YYYY-MM-DD")}
                            </p>
                            <p className="article-video-info-text">
                              {this.state.videoList[0].Title}
                            </p>
                          </div>
                          {/* <button
                            type="button"
                            className="btn article-video-length"
                          >
                            2:39
                          </button> */}
                          <div className="play-btn d-flex justify-content-center">
                            <img
                              className="play-img"
                              src={play_circle}
                              alt="play"
                            />
                          </div>
                        </Fragment>
                      ) : (
                        <Skeleton variant="rect" height={180} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <LiveNews />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <TrendingToday />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 mb-4">
            <div className="col-12">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 07") {
                    return (
                      <div className="ad mt-4 mb-4" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail}
                          alt="advertisement"
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          onError={this.addDefaultSrc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div style={{ width: "75%", height: "75%" }}>
              {this.state.videoSuccess && this.state.videoList.length > 0 ? (
                <ReactPlayer
                  controls={true}
                  width="100%"
                  height="100%"
                  url={this.state.videoList[this.state.videoId].URL}
                />
              ) : null}
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.livePostStore.liveNews,
    postsState: state.livePostStore.liveNewsList,
    videoState: state.videoStore.video,
    adState: state.appStore.ads,
    tagState: state.postStore.tags,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addCount: (data) => dispatch(addCount(data)),
  getAllAds: () => dispatch(getAllAds()),
  addViewCount: (data) => dispatch(addViewCount(data)),
  getPostByTag: (id) => dispatch(getPostByTag(id)),
  getLivePostById: (id) => dispatch(getLivePostById(id)),
  getAllVideo: (from, to) => dispatch(getAllVideo(from, to)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveArticle)
);
